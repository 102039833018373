import React, { Component } from "react";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";

class About extends Component {
    render() {
        return (
            <div>

                <Header />
                <div className="padding-top">
                    <div className="container">
                        <h1>About</h1>
                    </div>
                </div>
                <Fooer />
            </div>
        );
    }
}

export default About;