import React, { Component } from 'react'
import fire from '../config/fire'
import { withRouter, Link } from "react-router-dom";
import firebase from "firebase";
import { Button, Row, Col, Card, } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
class Dodaj_pjesme extends Component {
    constructor (props) {
        super(props);
        this.state = {



        };
    }

    resetForm() {
        this.refs.CheckoutFormForm.reset();

    }


    // PODACI ZA DOSTAVU POSLANI U BAZU PODATAKA
    sendMessage(e) {
        e.preventDefault();
        var myRef = fire.database().ref().push();
        const id_pjesme = myRef.key
        const broj = 0
        const params = {

            title: this.inputTitle.value,
            artist: this.inputArtist.value,
            slika: this.inputSlika.value,
            id_pjesme,
            broj

        };

        if (params.title && params.artist && params.slika) {
            fire.database().ref('pjesme').child(id_pjesme).set(params).then(() => {

                this.showAlert('success', 'Vaša pjesma je dodana!');
            }).catch(() => {
                this.showAlert('danger', 'Vaša pjesma ne može biti poslana');
            });



            this.resetForm()
            this.showAlert('success', 'Vaš pjesma je dodana!');
        } else {
            this.showAlert('warning', 'Molimo Vas popunite formu');
        };
    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }

    render() {

        return (
            <div className="container text-left">
                <div className="text-left pt-5">

                    <h1>Dodaj novu pjesmu</h1>
                </div>
                <form style={ { marginTop: '10px', textAlign: 'left !important' } } id="form" onSubmit={ this.sendMessage.bind(this) } ref='CheckoutFormForm' >
                    <Button className="button-blue"
                        type='submit'>Dodaj pjesmu</Button>
                    <div className="form-group">

                    </div>

                    <div className='form-group'>
                        <label className="text-left">Ime pjesme</label>
                        <input type='text' className='form-control' id='title' placeholder='' ref={ title => this.inputTitle = title } />
                    </div>

                    <div className='form-group'>
                        <label className="text-left">Izvođač</label>
                        <input type='text' className='form-control' id='artist' placeholder='' ref={ artist => this.inputArtist = artist } />
                    </div>
                    <div className='form-group'>
                        <label className="text-left">Slika</label>
                        <input type="url" className='form-control' id='title' placeholder='https://example.com/slika.jpg' ref={ slika => this.inputSlika = slika } />
                    </div>
                    { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } role='alert'>
                        <div className='container'>
                            { this.state.alertData.message }
                        </div>
                    </div> }





                </form>

            </div>
        )
    }


}
export default Dodaj_pjesme;