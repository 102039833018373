import { Link } from 'react-router-dom';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MusicLinks from './MusicLinks';
import fire from '../config/fire';

const History = () => {
    const [track, setTrack] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        let repeat;

        async function fetchData() {
            try {
                const res = await fetch("https://corsgroove.herokuapp.com/http://c5.hostingcentar.com:8307/played.html?sid=1");
                const json = await res.text();

                const jso = json
                setTrack(jso);
                fire.database().ref('povijest').set(jso)
                setLoading(false)

                repeat = setTimeout(fetchData, 4000); // request again after a minute
            } catch (error) {
                console.error(error.message)
            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }
    }, []);

    return (
        <div >
            <h1 style={ { padding: 30 } } className="groovin">Just played</h1>
            { loading === true ? <p className="text-center">Buffering...</p> : <div>            { ReactHtmlParser(track) }</div> }

        </div>
    );
};

export default History