import React, { Component } from 'react'
import fire from '../config/fire'
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase";
import 'bootstrap/dist/css/bootstrap.css';
import ContentLoader, { Facebook } from 'react-content-loader'
import Avatar from '@material-ui/core/Avatar';
import { Button, Row, Col, Card, } from '@material-ui/core';
import { withRouter } from 'react-router';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "firebase/auth";
class Details extends Component {
    constructor (props) {
        super(props);
        this.state = {
            uid: "YJYCjNgpqkWCq6mMklK97ew3qE2",
            pjesme: [],
            title: [],
            slika: [],
            broj: "",
            artist: [],
            kljuc: "",
            id: [],
            loading: true,
            language: false,
            id_pjesme: [],
            user: null,

            favoriti: []

        };
        this.ukloni = this.ukloni.bind(this)
        this.dodajufavorite = this.dodajufavorite.bind(this)
    }

    authListener() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = this.props.match.params.id
                const ud = firebase.auth().currentUser.uid
                console.log(ud)
                fire.database().ref(`/usersList/${ud}/favoriti/${id}`)

                    .on("value", snapshot => {
                        snapshot.forEach(child => {
                            this.setState({ kljuc: child.val().ids });
                        })
                    });
                this.setState({ user: true, uid: ud });

            }
            else {

                this.setState({ user: true, e: null });
            }
        })
    }

    componentWillMount() {

        const ordersRef = fire.database().ref(`pjesme`)

        ordersRef.once('value', snapshot => {
            snapshot.forEach(child => {
                const movie = child.val()
                if (movie.id_pjesme === this.props.match.params.id) {
                    this.setState({

                        pjesme: this.state.pjesme.concat([child.val().pjesme]),
                        title: this.state.title.concat([child.val().title]),
                        slika: this.state.slika.concat([child.val().slika]),
                        broj: this.state.broj.concat([child.val().broj]),
                        artist: this.state.artist.concat([child.val().artist]),
                        id_pjesme: this.state.id_pjesme.concat([child.val().id_pjesme]),
                        id: child.key,
                        loading: false

                    });

                }
            })

        })

        this.authListener()



    }



    dohvati() {



    }

    dodajufavorite() {
        const userId = this.state.uid
        const title = this.state.title
        const id_pjesme = this.state.id
        const ids = this.state.id
        const bro = this.state.broj * 1
        const broj = bro + 1
        const slika = this.state.slika
        const foodClass = { title, ids, slika }
        const _isTrue = this.state.kljuc === id_pjesme
        if (!_isTrue) {
            fire.database().ref(`usersList/${userId}/favoriti`).child(id_pjesme).push(foodClass)
            fire.database().ref(`pjesme/${id_pjesme}/broj`).set(broj).then(this.props.history.go())

        }
        else {
            console.log('lajkano vec')
        }

    }
    ukloni() {
        const userId = this.state.uid
        const title = this.state.title
        const id_pjesme = this.state.id
        const ids = this.state.id
        const slika = this.state.slika
        const bro = this.state.broj * 1
        const broj = bro - 1
        const foodClass = { title, ids, slika }
        const _isTrue = this.state.kljuc === id_pjesme
        fire.database().ref(`pjesme/${id_pjesme}/broj`).set(broj).then(
            fire.database().ref(`usersList/${userId}/favoriti`).child(this.props.match.params.id).remove().then(this.props.history.go())
        )


    }

    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }



    render() {
        console.log(this.state.kljuc)



        const _isTrue = this.state.kljuc === this.props.match.params.id
        const { match, location, history } = this.props
        return (
            <div className="padding-top" style={ { alignItems: 'center', margin: '0 auto', textAlign: 'left', minHeight: '100vh' } }>
                <div className="container- p-3">
                    <div className="page-header p-3">

                        <h3 onClick={ () => history.goBack() }><i class="fas fa-long-arrow-alt-left"></i> Go back</h3>

                    </div>

                    { this.state.loading ?
                        <div style={ { minHeight: '100vh' } }><Facebook /></div> :
                        <div className="row" >

                            <div className="col-sm" style={ { borderRadius: 11, marginLeft: '7px', margin: 'auto' } }>



                                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } role='alert'>
                                    <div className='container'>
                                        { this.state.alertData.message }
                                    </div>
                                </div> }

                                <div className="">
                                    <div className=" " style={ { display: "flex", flexWrap: "wrap", margin: '0px auto', width: '100%', justifyContent: 'left', } }>
                                        { this.state.pjesme.map((data, index) =>

                                            <div className="card  b-0" style={ { width: "100%", border: 0, background: 'transparent' } } >
                                                <div style={ { textAlign: 'center', background: 'white' } }>

                                                </div>
                                                <ul style={ { listStyleType: "none", padding: '20px', borderRadius: '10px' } } id="lista-detalji" className='text-left'>
                                                    <h1 className="text-left">{ this.state.title[index] }</h1>
                                                    <h4 className=""><b>{ this.state.artist[index] }</b></h4>


                                                    { this.state.kljuc }
                                                </ul>


                                            </div>

                                        ) }
                                    </div>


                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="d-flex">

                                    <div className="p-2"><p>Lajk { this.state.broj }</p></div>
                                    <div className="p-2"><p>Votes</p></div>
                                    { _isTrue ? <div className="p-2"><button onClick={ this.ukloni } className="button-blue btn">Dislike</button></div> : <div className="p-2"><button onClick={ this.dodajufavorite } className="button-blue btn">Lajkaj</button></div> }


                                </div>
                                <img id="slika" style={ { width: '100%', height: 'auto' } } alt={ this.state.title } src={ this.state.slika } />
                            </div>
                        </div> }

                </div>
            </div>
        )
    }


}
export default Details;