import React, { Component } from "react";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";

class Impressum extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="container padding-top">

                    <p>Naziv medijske usluge:
                    </p>
                    <h1>GROOVE</h1>
                    <p>Naknadnik:</p>
                    <p>ENTER ZAGREB d.o.o. za usluge</p>
                    <p>Sjedište: Zagreb, Avenija V. Holjevca 29
                    </p>

                    <p>Nadležni sud:
                        Trgovački sud u Zagrebu
                    </p>
                    <p>MBS:
                        080095307
                    </p>
                    <p>OIB:
                        60299905912
                    </p>
                    <p>EUID:
                        HRSR.080095307
                    </p>
                    <p>Uprava:
                        Dario Antunović
                    </p>

                    <p>Temeljni kapital iznosi 20.000,00 HRK (u cijelosti uplaćen). </p>
                    <p>Račun banke: IBAN:
                        HR8324020061100574599 otvoren kod Erste&Steiermärkische Bank d.d.</p>
                    <p>Mail:
                        kontakt@groove.com.hr
                    </p>
                    <p>Telefon:
                        00 385 6690 630 </p>
                    <p>Nadležni regulator:
                        Agencija za elektroničke medije</p>
                    <p>Broj dopuštenja:
                        46-21</p>
                    <p>__________</p>

                </div>
                <Fooer />
            </div>
        );
    }
}

export default Impressum;