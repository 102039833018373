import React, { Component } from 'react';
import fire from './config/fire';
import './App.css';

import Home from './Pages/Home';
import Routes from './Routes'

import Cookie from './Components/Cookie';
class App extends Component {


  render() {

    return (
      <div className="App">
        <Cookie />
        <Routes />

      </div>
    );
  }
}

export default App;
