import React from 'react';
import './App.css';
import { createBrowserHistory } from 'history';
import { Router, Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home"
import Played from './Pages/Played';
import Dodaj_pjesmu from './Pages/Dodaj-pjesmu';
import Lista_pjesama from './Pages/Lista_pjesama';
import Header from './Components/Navigacija';
import Login from './Login';
import Prijava from './Prijava';
import Fooer from './Components/Footer';
import Player from './Components/Player';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Events from './Pages/Events';
import SvePjesme from './Pages/SvePjesme';
import Detalji from './Components/Detalji';
import Details from './Components/Details';
import D from './PrimjerStareListe';
import Pjesme from './Pages/Pjesme';
import Music from './Pages/Music';
import Lista_dodanih_pjesama from './Components/Lista_dodanih_pjesama';
import Impressum from './Pages/Impressum';
import Cookie_policy from './Pages/Cookies-policy';
import Privacy from './Pages/Privacy-policy';
import Hist from './Components/Hist';

function Routes() {
    const customHistory = createBrowserHistory();
    return (

        <div className="App" >



            <BrowserRouter history={ customHistory } basename="/pages">
                <Switch>
                    <Route path='/' exact component={ Home }></Route>
                    <Route path='/history' exact component={ Played }></Route>
                    <Route path='/login' exact component={ Login } ></Route>
                    <Route path='/registracija' exact component={ Prijava } ></Route>
                    <Route path='/dodaj-pjesmu' exact component={ Dodaj_pjesmu }></Route>
                    <Route path='/lista-pjesama' exact component={ Lista_pjesama }></Route>
                    <Route path='/player' exact component={ Player }></Route>
                    <Route path='/about' exact component={ About }></Route>
                    <Route path='/contact' exact component={ Contact }></Route>

                    <Route path='/detalji/:id/' exact component={ Detalji }></Route>
                    <Route path='/pjesme' exact component={ SvePjesme }></Route>
                    <Route path='/details/:id/' exact component={ Details }></Route>
                    <Route path='/music' exact component={ Music }></Route>
                    <Route path='/sve-pjesme' exact component={ Pjesme }></Route>
                    <Route path='/impressum' exact component={ Impressum }></Route>
                    <Route path='/cookie' exact component={ Cookie_policy }></Route>
                    <Route path='/privacy-policy' exact component={ Privacy }></Route>
                    <Route path='/his' exact component={ Hist }></Route>
                </Switch>
            </BrowserRouter>

        </div>

    );
}

export default Routes;