import React, { Component } from "react";
import Product_list from "./Now_playing";
import $ from 'jquery'
import "./Player.css";
import Top from "./Top";
import Header from "./Navigacija";
import Fooer from "./Footer";
import Hist from "./Hist";
import History from "./History";
import Current from "./NowIMG";
class Player extends Component {

    constructor (props) {
        super(props);
        this.state = {





        };


    }
    componentDidMount() {


        /*
        ===============================================================
        
        Hi! Welcome to my little playground!
        
        My name is Tobias Bogliolo. 'Open source' by default and always 'responsive',
        I'm a publicist, visual designer and frontend developer based in Barcelona. 
        
        Here you will find some of my personal experiments. Sometimes usefull,
        sometimes simply for fun. You are free to use them for whatever you want 
        but I would appreciate an attribution from my work. I hope you enjoy it.
        
        ===============================================================
        */

        //===================
        //===================
        //Global:
        //===================
        //===================

        //Elements:
        var $source = $("#audiotrack")[0],
            $track = $("#track"),
            $progress = $("#progress"),
            $play = $("#play"),
            $pause = $("#pause"),
            $playPause = $("#play, #pause"),
            $stop = $("#stop"),
            $mute = $("#mute"),
            $volume = $("#volume"),
            $level = $("#level");

        //Vars:
        var totalTime,
            timeBar,
            newTime,
            volumeBar,
            newVolume,
            cursorX;

        var interval = null;


        $(document).ready(function () {
            //===================
            //===================
            //Track:
            //===================
            //===================

            //Progress bar:
            function barState() {
                if (!$source.ended) {
                    var totalTime = parseInt($source.currentTime / $source.duration * 100);
                    $progress.css({ "width": totalTime + 1 + "%" });
                }
                else if ($source.ended) {
                    $play.show();
                    $pause.hide();
                    clearInterval(interval);
                };
                console.log("playing...");
            };

            //Event trigger:
            $track.click(function (e) {
                if (!$source.paused) {
                    var timeBar = $track.width();
                    var cursorX = e.pageX - $track.offset().left;
                    var newTime = cursorX * $source.duration / timeBar;
                    $source.currentTime = newTime;
                    $progress.css({ "width": cursorX + "%" });
                };
            });


            //===================
            //===================
            //Button (Play-Pause):
            //===================
            //===================

            $("#pause").hide();

            function playPause() {
                if ($source.paused) {
                    $source.play();
                    $pause.show();
                    $play.hide();
                    interval = setInterval(barState, 50); //Active progress bar.
                    console.log("play");
                }
                else {
                    $source.pause();
                    $play.show();
                    $pause.hide();
                    clearInterval(interval);
                    console.log("pause");
                };
            };

            $playPause.click(function () {
                playPause();
            });


            //===================
            //===================
            //Button (Stop):
            //===================
            //===================
            function stop() {
                $source.pause();
                $source.currentTime = 0;
                $progress.css({ "width": "0%" });
                $play.show();
                $pause.hide();
                clearInterval(interval);
            };

            $stop.click(function () {
                stop();
            });


            //===================
            //===================
            //Button (Mute):
            //===================
            //===================
            function mute() {
                if ($source.muted) {
                    $source.muted = false;
                    $mute.removeClass("mute");
                    console.log("soundOFF");


                }
                else {

                    $source.muted = true;
                    $mute.addClass("mute");

                    console.log("soundON");
                };
            };

            $mute.click(function () {
                mute();
            });


            //===================
            //===================
            //Volume bar:
            //===================
            //===================
            $volume.click(function (e) {
                var volumeBar = $volume.width();

                var cursorX = e.pageX - $volume.offset().left;
                var newVolume = cursorX / volumeBar;
                $source.volume = newVolume;
                $level.css({ "width": cursorX + "px" });
                $source.muted = false;
                $mute.removeClass("mute");
            });


        }); //Document ready end.


    }

    render() {


        return (
            <div>
                <Header />
                <div className="container-fluid padding-top player-container" style={ { background: 'black' } }>

                    <div className="row ">
                        <div className="col-sm mb-4 ">
                            <div class="wrapper">

                                <div id="player">
                                    <div className="player-back" style={ { backgroundImage: "url(" + "https://i.pinimg.com/originals/59/94/b5/5994b5a21eadaf45cef834aba2c7e262.gif" + ")", } } >
                                        <Current />
                                    </div>
                                    <div className="player-wrapper">
                                        <h3 className="text-white font-family">FEEL THE GROOVE</h3>
                                        <Product_list />

                                        <div id="track-1">

                                        </div>


                                        <div id="controls">

                                            <div class="icon" id="play"><img style={ { width: '48px', height: '48px' } } src="http://admin.dzungla.hr/slikeTwo/plays.png" /></div>
                                            <div class="icon" id="pause"><img style={ { width: '48px', height: '48px' } } src="http://admin.dzungla.hr/slikeTwo/pausee.png" /></div>
                                            <div class="icon" id="stop"></div>

                                            <div class="icon" id="mute"><img style={ { width: '48px', height: '48px' } } src="http://admin.dzungla.hr/slikeTwo/volume.png" /></div>
                                            <div id="volume">
                                                <div id="level"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div></div>
                        <div className="col-sm">
                            <History />
                        </div>
                    </div>

                    <audio id="audiotrack" preload autoPlay src="http://c5.hostingcentar.com:8307/;">
                    </audio>


                </div>
                <Fooer />
            </div>
        );
    }
}

export default Player;