import React, { Component } from "react";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";

class Cookie_policy extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="padding-top">
                    <div className="container">
                        <h1>Cookie</h1>
                        <p class="p1"><strong>Politika kori&scaron;tenja kolačića ( Cookies Policy )<span class="Apple-converted-space">&nbsp;</span></strong></p>
                        <p class="p1">Ovom Politikom želimo Vam objasniti kako i za&scaron;to <strong>GROOVE radio</strong> na svojim web stranicama koristi kolačiće tzv. Cookies. Kori&scaron;tenjem kolačića omogućujemo vam bolju i bržu uslugu kori&scaron;tenja sadržaja kao i funkcionalnosti web stranice sa željom &scaron;to ugodnijeg korisničkog iskustva.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Stupanjem na snagu Uredbe o za&scaron;titi osobnih podataka ( EU 2016/679 ) te e-privacy direktive<span class="Apple-converted-space">&nbsp;&nbsp;</span>već pri prvom posjetu na&scaron;oj web stranici dužni smo Vas upoznati s uporabom kolačića te Vam pružiti mogućnost izbora i upravljanja kolačićima odnosno<span class="Apple-converted-space">&nbsp;&nbsp;</span>Va&scaron;e mogućnosti u dijelu njihovog prihvaćanja ili ne prihvaćanja. Molimo Vas da shvatite da Va&scaron;im odbijanjem kori&scaron;tenja pojedinih vrsta kolačića ne možemo u potpunosti garantirati svu funkcionalnost web stranice &scaron;to može rezultirati i Va&scaron;im smanjenim doživljajem web stranice odnosno usluge njenog kori&scaron;tenja.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p1">Pravna osnova za kori&scaron;tenje kolačića sukladno GDPR-u temelji se na Va&scaron;em pristanku ( Art. 6 (a ),<span class="Apple-converted-space">&nbsp;&nbsp;</span>odnosno temeljem legitimnog interesa pružanja &scaron;to kvalitetnije tražene usluge od strane korisnika Art 6. ( f ). Ukoliko ste, nakon &scaron;to se upoznate s kori&scaron;tenjem kolačića (cookies), <span class="Apple-converted-space">&nbsp;&nbsp;</span>označili &bdquo; pristajem&ldquo;,<span class="Apple-converted-space">&nbsp;&nbsp;</span>smatramo da ste suglasni s kori&scaron;tenjem kolačića na ovoj web stranici za koje je potrebna Va&scaron;a suglasnost odnosno privola . Također, ovom Politikom želimo Vas informirati na koje načine možete obrisati , aktivirati ili deaktivirati pojedine kolačiće.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p2"><br /></p>
                        <p class="p1"><strong>&Scaron;TO SU KOLAČIĆI ( cookies )</strong></p>
                        <p class="p1">Kolačići su male najče&scaron;će tekstualne datoteke koje se automatski instaliraju na Va&scaron;e računalo ( u Va&scaron; Internet preglednik )<span class="Apple-converted-space">&nbsp;&nbsp;</span>kada posjetite web stranicu a omogućuju između ostalog . &bdquo;prepoznavanje&bdquo; korisničkog (Va&scaron;eg) računala od strane web stranice. Postoji vi&scaron;e vrsta kolačića pa sukladno tome i njihove funkcije su različite. Kolačići mogu prikupljati različite informacije najče&scaron;će sa svrhom lak&scaron;eg i bržeg kori&scaron;tenja interneta te pristupa pojedinim web stranicama ali mogu sadržavati i podatke o geolokaciji , učestalosti posjećivanja web stranica, pregledu određenog sadržaja i va&scaron;ih preferencija ili pohranjivati određene zaporke s ciljem lak&scaron;eg ponovnog pristupa određenim stranicama i sadržajima. Kolačići mogu prepoznati jeste li prvi puta posjetili web lokaciju ili ste već prije posjetili stranicu. U načelu Kolačići ne prikupljaju osobne podatke i u zaista iznimnim situacijama kombinacijom vi&scaron;e vrsta kolačića i upisanih podataka od strane korisnika koji se<span class="Apple-converted-space">&nbsp;&nbsp;</span>prikupljaju mogu dovesti do &bdquo;mogućnosti&ldquo; identifikacije korisnika.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Iako se s napretkom tehnologije i novih IT mogućnosti terminologije kolačića mijenjaju, u nastavku navodimo najče&scaron;će kolačiće (cookies) koji se koriste te način na koji njima možete upravljati kako bi bolje razumjeli Politiku kori&scaron;tenja Kolačića.</p>
                        <p class="p3"><br /></p>
                        <p class="p1"><strong>VRSTE KOLAČIĆA:<span class="Apple-converted-space">&nbsp;</span></strong></p>
                        <ol class="ol1">
                            <li class="li4"><span class="s1"><strong>Neophodni Kolačići ( cookies )<span class="Apple-converted-space">&nbsp;</span></strong></span></li>
                        </ol>
                        <p class="p1">Ovi kolačići su neophodni za određene funkcionalnosti web stranice. Npr. ukoliko stranica funkcionira samo na određenom geografskom području, bez ovih kolačića na&scaron;a web stranica ne bi bila funkcionalna i ne bi joj mogli pristupiti. Brisanjem ili deaktiviranjem ovih kolačića pojedine funkcionalnosti web stranice bile bi umanjene ili ukinute.</p>
                        <p class="p2"><br /></p>
                        <p class="p2"><br /></p>
                        <ol class="ol1">
                            <li class="li4"><span class="s1"><strong>Kolačići ( Cookies ) za analitiku<span class="Apple-converted-space">&nbsp;</span></strong></span></li>
                        </ol>
                        <p class="p1">Ovi kolačići nam omogućavaju da znamo koliki je ukupni broj posjeta web stranici kako bi mogli mjerenjem posjeta i čitanja određenih sadržaja na&scaron;e web stranice učiniti jo&scaron; boljima, pristupačnijima i zanimljivijima. Ovi kolačići nam omogućavaju da razumijemo kako koristite na&scaron;e web stranice te nam omogućavaju bolju funkcionalnost i mogućnost da unaprijedimo na&scaron;e usluge . Ponavljamo ovi kolačići ne prikupljaju nikakve osobne podatke korisnika niti mogu dovesti do njegove identifikacije<span class="Apple-converted-space">&nbsp;</span></p>
                        <ol class="ol1">
                            <li class="li4"><span class="s1"><strong>Kolačići ( cookies ) za Funkcionalnost</strong></span></li>
                        </ol>
                        <p class="p1">Kori&scaron;tenjem ovih kolačića osiguravamo da pojedini sadržaji , operacije ili usluge mogu normalno funkcionirati i izvr&scaron;avati svoju svrhu.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Npr. neki od tih kolačića Vam omogućavaju slu&scaron;anje na&scaron;eg radija preko Web-a. ( web streaming ) i sl.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p1">Osim po svrsi koju imaju, Kolačiće ( cookie ) može podijeliti i po njihovom trajanju odnosno vremenu pohranjivanja na na&scaron;em računalu , kao i izvoru. Tako razlikujemo stalne i<span class="Apple-converted-space">&nbsp;&nbsp;</span>povremene odnosno lokalne ( first party cookies ) i<span class="Apple-converted-space">&nbsp;&nbsp;</span>kolačiće treće strane ( third party cookies ).</p>
                        <ol class="ol1">
                            <li class="li4"><span class="s1"><strong>Kolačići za sigurnost i detekciju opasnosti</strong></span></li>
                        </ol>
                        <p class="p1">Ovi kolačići usmjereni su prema sigurnosti kori&scaron;tenja web stranice kako bi se detektirale i spriječile zlonamjerne aktivnosti.</p>
                        <p class="p2"><br /></p>
                        <p class="p1"><strong>VRSTE KOLAČIĆA PREMA VREMENU TRAJANJA :</strong></p>
                        <p class="p5"><strong>a ) Stalni kolačići</strong> &ndash; ostaju pohranjeni u na&scaron;em računalu i nakon &scaron;to smo zatvorili web stranicu. Neki imaju rok trajanja i nakon nekog vremena sami će se obrisati ili ih možemo ručno obrisati.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p5"><strong>b ) Povremeni kolačići</strong> &ndash; nakon &scaron;to zatvorimo web stranicu ovaj tip kolačića se bri&scaron;e iz memorije na&scaron;eg računala.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p2"><br /></p>
                        <p class="p1"><strong>VRSTE KOLAČIĆA PREMA LOKACIJI :</strong></p>
                        <p class="p5"><span class="s2">&rArr;</span><strong>&nbsp;First party cookies</strong> &ndash; pohranjuju se na na&scaron;e računalo (trajno ili povremeno) a izvor je web lokacija koju smo posjetili.</p>
                        <p class="p5"><span class="s2">&rArr;</span><strong>&nbsp;Third party cookies</strong> - potječu od drugih web-mjesta koja posjećujete. Treće strane mogu na taj način prikupljati podatke o korisnicima drugih web lokacija i upotrebljavati ih u analitičke svrhe ( ili marketin&scaron;ke ). Ove kolačiće možete onemogućiti kao i neke gore navedene.</p>
                        <p class="p2"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p1"><strong>VRSTE KOLAČIĆA ZA KOJE NIJE POTREBNA SUGLASNOST KORISNIKA</strong></p>
                        <p class="p6">Prema e-Privacy direktivi Europske Unije postoje kolačići uglavnom tehničke prirode (neophodni kolačići)<span class="Apple-converted-space">&nbsp;&nbsp;</span>za koje nije potrebna suglasnost korisnika.</p>
                        <p class="p7"><span class="s3">( <a href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies#section_5"><span class="s4">https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies#section_5</span></a>)<span class="Apple-converted-space">&nbsp;</span></span></p>
                        <p class="p8"><em>In line with Article 5(3) of the ePrivacy Directive, consent is not required for technical storage or access of the following cookies:</em></p>
                        <ul class="ul1">
                            <li class="li9"><span class="s1"><em>cookies&nbsp;used for the sole purpose of carrying out the transmission of a communication</em></span></li>
                            <li class="li10"><span class="s1"><em>cookies that are strictly necessary in order for the provider of an information society service explicitly required by the user to provide that service</em></span></li>
                        </ul>
                        <p class="p8"><em>Examples of cookies that generally do NOT require consent:</em></p>
                        <ul class="ul1">
                            <li class="li9"><span class="s1"><em>user input cookies, for the duration of a session</em></span></li>
                            <li class="li9"><span class="s1"><em>authentication cookies, for the duration of a session</em></span></li>
                            <li class="li9"><span class="s1"><em>user-centric security cookies, used to detect authentication abuses and linked to the functionality explicitly requested by the user, for a limited persistent duration</em></span></li>
                            <li class="li9"><span class="s1"><em>multimedia content player session cookies, such as flash player cookies, for the duration of a session</em></span></li>
                            <li class="li9"><span class="s1"><em>load balancing session cookies and other technical cookies, for the duration of session</em></span></li>
                            <li class="li4"><span class="s1"><em>user interface customisation cookies, for a browser session or a few hours, when additional information in a prominent location is provided (e.g. &ldquo;uses cookies&rdquo; written next to the customisation feature)</em></span></li>
                        </ul>
                        <p class="p2"><br /></p>
                        <p class="p1"><strong>Kolačići &ndash; GROOVE radio</strong></p>
                        <p class="p1"><strong>GROOVE radio</strong> na svojim web stranicama koristi stalne ( persistent )<span class="Apple-converted-space">&nbsp;&nbsp;</span>i povremene ( session ) kolačiće kao i<span class="Apple-converted-space">&nbsp;&nbsp;</span>neophodne kolačiće , kolačiće za funkcionalnost, kolačiće za analitiku i sigurnost.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p1">Web stranica <strong>GROOVE radio</strong> može dopustiti pojedinim servisima da postave tzv. Third-party cookies sa svrhom analitike funkcionalnosti stranice &scaron;to uključuje skupljanje informacije o aktivnostima korisnika na matičnoj web stranici ili putem linkova na web stranice drugih partnera ili servisa ( npr. Google analytics ). Za vi&scaron;e informacija kako Google koristi i analizira podatke molimo vas da posjetite <a href="https://policies.google.com/technologies/partner-sites"><span class="s6">https://policies.google.com/technologies/partner-sites</span></a></p>
                        <p class="p1">Na na&scaron;im web stranicama također nalaze se poveznice<span class="Apple-converted-space">&nbsp;&nbsp;</span>koji Vas vode na web lokacije dru&scaron;tvenih mreža ( Youtube, Facebook , Instagram i sl. ). S obzirom na to da nemamo kontrolu nad podacima koje prikupljaju i obrađuju treće strane, nismo u mogućnosti<span class="Apple-converted-space">&nbsp;&nbsp;</span>pružiti obvezujuće informacije u svezi s ciljem i svrhom takvog tipa obrade Va&scaron;ih podataka niti za to možemo preuzeti odgovornost posebno u dijelu privatnosti.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Za vi&scaron;e informacija u svezi cilja i svrhe takvog prikupljanja i obrade Va&scaron;ih podataka, pogledajte izjave o privatnosti trećih strana<span class="Apple-converted-space">&nbsp;&nbsp;</span>čije usluge , sadržaj ili poveznice smo uključili i koji su odgovorni za za&scaron;titu Va&scaron;ih podataka.</p>
                        <p class="p1">Postoje i Kolačići koji omogućavaju Personalizaciju. <span class="s6">Ovaj tip kolačića&nbsp;</span><strong>GROOVE radio</strong><span class="s6">&nbsp;ne koristi&nbsp;</span>a omogućavaju personaliziranu prilagodbu web sučelja sukladno Va&scaron;im preferencijama.</p>
                        <p class="p2"><br /></p>
                        <p class="p3"><br /></p>
                        <p class="p1"><strong>UPRAVLJANJE KOLAČIĆIMA<span class="Apple-converted-space">&nbsp;</span></strong></p>
                        <p class="p4">Upravljanje kolačićima moguće je već od prvog posjeta na&scaron;oj web stranici. U modulu privole vezano uz instalaciju kolačića na va&scaron;e računalo, možete odlučiti koje kolačiće želite instalirati a koje ne. Podsjećamo da instalacijom određenih kolačića koji služe isključivo za statistiku i analitiku posjeta i kori&scaron;tenja sadržaja dajete nam mogućnost kontinuiranog usavr&scaron;avanja web stranice kako u dijelu njene funkcionalnosti i dizajna tako i u dijelu njenog sadržaja &scaron;to je na obostranu korist. Kolačiće (cookies) možete izbrisati ili onemogućiti njihovo kori&scaron;tenje i u svojem Internet pregledniku, možete također postaviti opciju automatskog blokiranja kolačića i sprječavanja njihovog instaliranja na računalo. Ukoliko želite vi&scaron;e informacija kako upravljati kolačićima sukladno vrsti internet preglednika koji koristite savjetujemo da posjetite slijedeće web stranice:</p>
                        <ul class="ul1">
                            <li class="li4"><a href="https://aboutcookies.org/"><span class="s6">aboutcookies.org</span></a></li>
                            <li class="li4"><a href="http://www.allaboutcookies.org"><span class="s6">www.allaboutcookies.org</span></a></li>
                        </ul>
                        <p class="p4">Ukoliko onemogućite kori&scaron;tenje pojedinih kolačića na web stranici <strong>GROOVE radio</strong> i dalje ćete moći pregledavati web stranicu ali ne možemo garantirati da će<span class="Apple-converted-space">&nbsp;&nbsp;</span>u cijelosti biti funkcionalna a time možda nećete biti u mogućnosti koristiti sve njene usluge i sadržaje.</p>
                        <p class="p4">Kori&scaron;tenje Kolačića (cookies) na svojim Web stranicama <strong>GROOVE radio</strong> koristi isključivo za povećanje funkcionalnosti stranice kao i bolje korisničko iskustvo i doživljaj. Želja nam je da Vam ovom Politikom Kolačića pružimo mogućnost kontrole Va&scaron;e privatnosti.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Kolačiće ne koristimo za bilo kakvu identifikaciju korisnika ili prikupljanje osobnih podataka već isključivo za općenitu anonimnu analitiku web stranice i njene posjećenosti te njenu funkcionalnost &scaron;to je definirano i u na&scaron;oj <span class="s6">Izjavi o privatnosti</span> a sa željom da maksimalno pobolj&scaron;amo pruženu uslugu i svrhu web stranice.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p11"><br /></p>
                        <p class="p4"><strong>KONTAKT</strong></p>
                        <p class="p1">Preporučamo da povremeno provjerite na&scaron;u Politiku kori&scaron;tenja Kolačića kao i Izjavu o Privatnosti jer povremeno može doći do nadopune istih sukladno Zakonskoj regulativi ili novim tehnolo&scaron;kim mogućnostima kao i mogućim novim uslugama i sadržajima.</p>
                        <p class="p4">Za sve dodatne informacije i upite oko kori&scaron;tenja kolačića kontaktirajte nas pismeno na adresu <strong>Enter Zagreb d.o.o. , Av.V. Holjevca 29, 10 000 Zagreb</strong><span class="Apple-converted-space">&nbsp;&nbsp;</span>ili putem e-mail adrese : <a href="mailto:sluzbenik@enterzagreb.hr"><span class="s4">sluzbenik@enterzagreb.hr</span></a></p>
                    </div>
                </div>
                <Fooer />
            </div>
        );
    }
}

export default Cookie_policy;