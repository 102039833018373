import React, { Component } from "react";
import fire from "../config/fire";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Row, Col, Card, } from '@material-ui/core';
import $ from 'jquery'
import { Link } from "react-router-dom";
import firebase from "firebase";
import MusicLinks from "../Components/MusicLinks";
import Lista_dodanih_pjesama from "../Components/Lista_dodanih_pjesama";
import Header from "../Components/Navigacija";
import Fooer from "../Components/Footer";

class Pjesme extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            kljuc: [],
            lajk: '',
            is: '',
            av: [],
            voted: false,
            stisnuto: false,
            loading: true,
            user: null,
            uid: ""

        };


    }

    componentDidMount() {

        let formRef = fire.database().ref('/pjesme')
        formRef.on('value', child => {
            this.setState({
                pjesme: child.val(),
                loading: false

            });
        })


        const user = this.state.uid
        fire.database().ref(`users/${user}`)
            .on("value", snapshot => {
                snapshot.forEach(child => {
                    this.setState({ kljuc: child.val() });


                })
            });
        this.authListener()

    }
    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })
    }


    vote(id, bro, e) {
        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro

        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: true }))

            .then(() => {

                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('yellow', 'Potvrdi svoj lajk!');
            }).catch(() => {

            });
    }

    voteTwo(id, bro, e) {

        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro
        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: false }))

            .then(() => {
                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('success', 'Vaš glas je dodan!');
            }).catch(() => {
                this.showAlert('danger', 'Vaš glas je ne može biti dodan!');
            });
        this.showAlert('yellow', 'Glasati možete jednom!');
    }
    get(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: true })
                this.showAlert('yellow', 'Potvrdi za uklanjanje lajka');
            }).catch(() => {
            });
    }
    getTwo(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: false })
                this.showAlert('danger', 'Vaš glas je uklonjen!');
            }).catch(() => {
                this.showAlert('danger', 'Greška');
            });
        this.showAlert('danger', 'Vaš glas je uklonjen!');
    }

    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }
    guest() {
        firebase.auth().signInAnonymously()
            .then(this.setState({ user: true }), this.showAlert('success', 'Uspješno ste prijavljeni!'))
            .catch(error => {
                this.setState({ user: true }, () => {

                })
            });
    }

    render() {

        return (
            <div>
                <Header />
                <div className="padding-top" >
                    <MusicLinks />
                    <Lista_dodanih_pjesama />

                </div >
                <Fooer />
            </div>
        );
    }
}

export default Pjesme;