import React, { Component } from "react";
import Fooer from "../Components/Footer";
import History from "../Components/History";
import Header from "../Components/Navigacija";
class Played extends Component {
    render() {
        return (
            <div>
                <Header />
                <History />
                <Fooer />
            </div>
        );
    }
}

export default Played;