import React, { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';
// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class MusicLinks extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="container pb-3 bg-transparent d-flex justify-content-center music-links">
                    <button className="btn button-blue text-white mr-2"><Link to="/music" className="text-white">Top 10</Link></button>
                    <button className="btn button-blue text-white mr-2"><Link to="/sve-pjesme" className="text-white"> { t('allsongs') }</Link></button>
                    <button className="btn button-blue text-white mr-2"><Link to="/history" className="text-white">{ t('justplayed') }</Link></button>

                </div>
            </div>
        );
    }
}
MusicLinks.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(MusicLinks);