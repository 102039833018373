import React, { Component } from "react";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";

class Privacy extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="padding-top">
                    <div className="container">
                        <h1 class="p1"><strong>IZJAVA O PRIVATNOSTI ( Privacy statment )</strong></h1>
                        <p class="p2"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Prikupljanje podataka</strong></li>
                        </ol>
                        <p class="p3">1.1 Ovom Izjavom o privatnosti ( Privacy Statement ) kao digitalni GROOVE radio<span class="Apple-converted-space">&nbsp;&nbsp;</span>želimo Vam potvrditi da su nam sve informacije vezane uz Va&scaron;e osobne podatke izuzetno važne i da za&scaron;titu Va&scaron;ih osobnih podataka smatramo vrlo ozbiljnom i tome pristupamo s velikom profesionalnom odgovorno&scaron;ću u skladu s Općom Uredbom o za&scaron;titi osobnih podataka (EU2016/679)<span class="Apple-converted-space">&nbsp;&nbsp;</span>te pripadajućim nacionalnim zakonskim i podzakonskim aktima o provedbi Uredbe i za&scaron;titi osobnih podataka .</p>
                        <p class="p3">1.2 U nastavku<span class="Apple-converted-space">&nbsp;&nbsp;</span>teksta želimo vas upoznati s načinom prikupljanja Va&scaron;ih osobnih podataka slu&scaron;anjem radija preko web stranice, kori&scaron;tenja na&scaron;e mobilne aplikacije i dru&scaron;tvenih mreža,<span class="Apple-converted-space">&nbsp;&nbsp;</span>sudjelovanja u na&scaron;im promotivnim projektima i nagradnim igrama ili bilo kojim drugim načinom kojim nam možete ustupiti va&scaron;e osobne podatke.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Također, želimo vas upoznati na koji način obrađujemo Va&scaron;e osobne podatke , u kojem obimu, kako ih čuvamo te Vas ujedno upoznati i s Va&scaron;im pravima, kao i načinom kako da s nama stupite u kontakt.</p>
                        <p class="p3">1.3 Ova Izjava o privatnosti ne odnosi se na one dijelove na&scaron;e web stranice<span class="Apple-converted-space">&nbsp;&nbsp;</span>i web providere (treće strane), koji omogućavaju dodatne sadržaje,<span class="Apple-converted-space">&nbsp;&nbsp;</span>a koji će Va&scaron;e osobne podatke &scaron;titi svojim Pravilnikom o za&scaron;titi osobnih podataka odnosno Politikom privatnosti ukoliko ih koristite (npr. youtube kanal, Faccebook i sl) .</p>
                        <p class="p3">1.4 Prema svim podacima kojima pristupamo ili obrađujemo postupamo sa povjerljivo&scaron;ću i svrhom, te najvi&scaron;im sigurnosnim standardima. Za obradu podataka odgovoran je:</p>
                        <p class="p4"><strong>Enter Zagreb<span class="Apple-converted-space">&nbsp;&nbsp;</span>d.o.o.</strong></p>
                        <p class="p4"><strong>Av. V. Holjevca 29, 10 000 Zagreb</strong></p>
                        <p class="p4"><strong>Tel: 00385(1) 6690 600 ( 601 )</strong></p>
                        <p class="p3">Službenik za za&scaron;titu osobnih podataka dostupan je putem<span class="Apple-converted-space">&nbsp;&nbsp;</span>e-mail adrese : <a href="mailto:sluzbenik@enterzagreb.hr"><span class="s1">sluzbenik@enterzagreb.hr</span></a> ili putem telefona : 01 6690 600 ( 601 ).</p>
                        <p class="p2"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Za&scaron;to smo usvojili Pravila privatnosti</strong></li>
                        </ol>
                        <p class="p5">2.1 Putem ove Izjave želimo vas informirati<span class="Apple-converted-space">&nbsp;&nbsp;</span>da smo<span class="Apple-converted-space">&nbsp;&nbsp;</span>kreirali i usvojili Politiku Privatnosti<span class="Apple-converted-space">&nbsp;&nbsp;</span>kako bi osigurali da je GROOVE radio usklađen sa zahtjevima:</p>
                        <p class="p6">&bull; &nbsp; &nbsp;Važeće zakonske regulative za za&scaron;titu i obradu osobnih podataka;</p>
                        <p class="p6">&bull; &nbsp; &nbsp;&Scaron;tićenja svih osobnih podataka s kojima dolazimo u kontakt;</p>
                        <p class="p7">&bull; &nbsp; &nbsp;Otvorenost i transparentnost prema svim korisnicima i ispitanicima o tome kako pohranjujemo i procesiramo osobne podatke;</p>
                        <p class="p6">&bull; &nbsp; &nbsp;Umanjenja rizika povrede osobnih podataka;</p>
                        <p class="p6">&bull; &nbsp; &nbsp;Edukacija i informiranja svih na&scaron;ih slu&scaron;atelja i ispitanika;</p>
                        <p class="p6">&bull; &nbsp; &nbsp;Povećanje transparentnosti obrade osobnih podataka i komunikacije</p>
                        <p class="p8"><br /></p>
                        <p class="p8"><br /></p>
                        <p class="p8"><br /></p>
                        <p class="p9">2.2 Pravne osnove za obradu osobnih podataka regulirane su propisima EU, odnosno Uredbom (EU 2016/679) i Zakonom RH o provedbi Opće uredbe o za&scaron;titi podataka i one su sljedeće:</p>
                        <p class="p8"><br /></p>
                        <ul class="ul1">
                            <li class="li10"><span class="s3">kada ste nam dali Va&scaron; pristanak za obradu (privola),</span></li>
                            <li class="li10"><span class="s3">kada je to potrebno za sklapanje ili izvr&scaron;avanje ugovora,</span></li>
                            <li class="li10"><span class="s3">u svrhu ispunjavanja zakonskih obveza,</span></li>
                            <li class="li10"><span class="s3">u svrhu legitimnih interesa GROOVE radija<span class="Apple-converted-space">&nbsp;&nbsp;</span>u odnosu na va&scaron;a prava glede za&scaron;tite osobnih podataka,</span></li>
                            <li class="li10"><span class="s3">kada je to potrebno u pravne svrhe.</span></li>
                        </ul>
                        <p class="p2"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Vrste osobnih podataka i obrada</strong></li>
                        </ol>
                        <p class="p3">3.1 Obrada osobnih podataka je postupak ili skup postupaka koji se obavljaju<span class="Apple-converted-space">&nbsp;&nbsp;</span>na osobnim podacima ili skupovima osobnih podataka (automatiziranim ili neautomatiziranim sredstvima ) &scaron;to podrazumijeva: prikupljanje , bilježenje, organizacija, strukturiranje, pohrana, prilagodba ili izmjena, pronalaženje obavljanje uvida, uporaba, otkrivanje prijenosom, &scaron;irenjem ili stavljanjem na raspolaganje na drugi način, usklađivanje ili kombiniranje, ograničavanje, brisanje ili uni&scaron;tavanje</p>
                        <p class="p3">3.3 U sklopu redovitog poslovanja prepoznajemo slijedeće kategorije osobnih podataka: temeljni osobni podaci: ime i prezime, adresa i kontakt podaci (adresa e-po&scaron;te, broj telefona).<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p3">3.3<strong><span class="Apple-converted-space">&nbsp;&nbsp;</span></strong>Posebne kategorije osobnih podataka su osjetljivi podaci ili podaci vrlo osobne naravi, a koji mogu uključivati biometrijske ili genetske podatke, te podatke koji se odnose na zdravlje. U sklopu svojeg redovitog poslovanja, ne prikupljamo podatke posebnih kategorija.</p>
                        <p class="p11"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Vrste ispitanika</strong></li>
                        </ol>
                        <p class="p11"><br /></p>
                        <ul class="ul1">
                            <li class="li3">Slu&scaron;atelji</li>
                            <li class="li3">Poslovni partneri i treće strane</li>
                            <li class="li3">Kandidati za zaposlenje na GROOVE radio</li>
                        </ul>
                        <p class="p12"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Prikupljanje podataka</strong></li>
                        </ol>
                        <p class="p3">Na koje načine u sklopu na&scaron;eg redovitog poslovanja prikupljamo va&scaron;e podatke:</p>
                        <p class="p3">5.1 Va&scaron;e osobne podatke prikupljamo samo onda kada nam Vi dajete za to svoj pristanak odnosno kada nam ih svojevoljno stavite na raspolaganje.</p>
                        <p class="p3">a) <strong>slu&scaron;atelji</strong> (Va&scaron;e osobne podatke prikupljamo, putem elektroničke po&scaron;te, izravnim telefonskim kontaktom, putem dru&scaron;tvenih mreža (Facebook / Instagram / YouTube ), putem mobilnih komunikacijskih aplikacija ili putem kontakt obrasca na web stranicama, mobilnoj aplikaciji ili nekim drugim oblikom direktne komunikacije).</p>
                        <p class="p3">b) <strong>poslovni partneri i treće strane</strong> (Va&scaron;e podatke prikupljamo temeljem Ugovora o poslovnoj suradnji i poslovnoj komunikaciji).</p>
                        <p class="p3">c) <strong>kandidati za zaposlenje</strong> (Va&scaron;e osobne podatke prikupljamo temeljem Va&scaron;e prijave na objavljeni oglas o potrebi za novim djelatnicima).</p>
                        <p class="p3">5.2 Informacije koje tako prikupimo u obimu mogu biti različite, ovisno o gore navedenom razlogu (nagradna igra, komentari u programu, sudjelovanje u promotivnim projektima koje organiziramo i sl.) a mogu sadržavati isključivo temeljne osobne podatke ili dio tih podataka neophodan za navedenu srhu. U svim slučajevima vodimo računa o načelima obrade osobnih podataka definiranih Uredbom a posebno u dijelu primjerenosti, relevantnosti i ograničenosti u odnosu na svrhu ( Članak.<span class="Apple-converted-space">&nbsp;&nbsp;</span>5. EU<span class="Apple-converted-space">&nbsp;&nbsp;</span>Opća Uredba o za&scaron;titi podataka, &bdquo; Načela obrade osobnih podataka &bdquo;.)</p>
                        <p class="p3">5.3 Va&scaron;im dobrovoljnim sudjelovanjem u na&scaron;em programskom sadržaju putem na&scaron;ih komunikacijskih kanala (elektroničke po&scaron;te, putem dru&scaron;tvenih mreža,<span class="Apple-converted-space">&nbsp;&nbsp;</span>mobilnih aplikacija za komunikaciju ( npr. WhatsApp, Viber, Signal i sl. ) ili telefonskim kontaktom)<span class="Apple-converted-space">&nbsp;&nbsp;</span>smatramo da ste nam dali privolu za obradu osobnih podataka koje prikupljamo na taj način<span class="Apple-converted-space">&nbsp;&nbsp;</span>sukladno ovoj Izjavi a za navedenu svrhu programskog sadržaja<span class="Apple-converted-space">&nbsp;&nbsp;</span>ili redovitog odvijanja programa GROOVE radija.</p>
                        <p class="p3">5.4 Bilo koje osjetljive informacije ( Članak 3.3 ) nisu predmet na&scaron;eg interesa i nećemo ih prikupljati bez Va&scaron;eg izričitog odobrenja .</p>
                        <p class="p3">5.5 Postoji mogućnost da ćemo dio informacija o Vama dobiti i preko trećih strana poput linkova na va&scaron;e dru&scaron;tvene mreže ili javno dostupnih izvora.</p>
                        <p class="p3">5.6 Postoji mogućnost prikupljanja kontakt<span class="Apple-converted-space">&nbsp;&nbsp;</span>informacija o nekim trećim stranama ili osobama.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Ako<span class="Apple-converted-space">&nbsp;&nbsp;</span>kroz programske sadržaje i situacije (npr. nagradne igre) pružate informacije o nekim drugim vama poznatim osobama, lijepo Vas molimo da one o tome budu informirane i da postoji suglasnost da ih se kontaktira, odnosno da su upoznati za &scaron;to se koriste njihovi osobni podaci.</p>
                        <p class="p3">5.7 Postoji mogućnost i prikupljanja informacija koje ne ulaze u kategoriju temeljnih osobnih podataka jer ne postoji mogućnost povezivanja tih informacija s Vama ili nekim drugim ispitanikom u smislu identifikacije. Na primjer, postoji mogućnost sudjelovanja u anonimnim anketama, prikupljanja geo-lokacijskih informacija vezanih uz promet, kompjuterskih i komunikacijskih informacija te statističkih informacija o posjetu web stranicama ili mobilnoj aplikaciji, te drugim standardnim web log informacijama. Te će se informacije koristiti isključivo za potrebe statistike i sukladno e-privacy direktivi za dio tih informacija potrebna je Va&scaron;a privola &scaron;to je regulirano u na&scaron;em Pravilniku o kori&scaron;tenju kolačića ( cookies ).<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p3">5.8 Na na&scaron;oj Internet stranici koristimo cookies , tzv. &bdquo; kolačiće&ldquo;, koji pohranjuju određene informacije. Na&scaron;a internet stranica koristi tehnički potrebne kolačiće koji su nužni za ispravno funkcioniranje web stranice. Svi podaci prikupljeni preko kolačića koriste se isključivo za bolju funkcionalnost rada web stranice i statističku analitiku te ne identificiraju osobne podatke. Naravno, uporabu kolačića možete i isključiti ali<span class="Apple-converted-space">&nbsp;&nbsp;</span>to može dovesti do ograničene funkcionalnosti i može utjecati na prikaz sadržaja i pristupačnost web stranice. Vi&scaron;e informacija o kori&scaron;tenju kolačića &bdquo; cookies&ldquo;<span class="Apple-converted-space">&nbsp;&nbsp;</span>i kako upravljati njima možete pronaći u na&scaron;em Pravilniku o kori&scaron;tenju kolačića.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p13"><strong>6. <span class="Apple-converted-space">&nbsp;&nbsp;</span>Dru&scaron;tvene mreže i obrada osobnih podataka<span class="Apple-converted-space">&nbsp;</span></strong></p>
                        <p class="p3">6.1 U svojem poslovanju i realizaciji programskih sadržaja GROOVE radio može koristiti i dru&scaron;tvene mreže za komunikaciju sa slu&scaron;ateljima kao i za realizaciju određenih sadržaja ili promotivnih projekata.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p3">6.2 GROOVE radio se sukladno ovoj Izjavi o Privatnosti<span class="Apple-converted-space">&nbsp;&nbsp;</span>obvezuje koristiti prikupljene osobne podatke putem dru&scaron;tvenih mreža samo za one srhe koje su navedene i komunicirane putem kanala dru&scaron;tvene mreže GROOVE radija i u onoj mjeri koliko je to nužno za navedenu svrhu i nije odgovoran za kori&scaron;tenje i obradu osobnih podataka od strane Dru&scaron;tvene mreže za neke druge svrhe. Svaka dru&scaron;tvena mreža ili aplikacija ima svoju Politiku privatnosti i korisnici iste su njenim kori&scaron;tenjem dali svoju privolu na obradu osobnih podataka.</p>
                        <p class="p14"><span class="s5">6.3 Ukoliko<span class="Apple-converted-space">&nbsp;&nbsp;</span>tijekom kori&scaron;tenja dru&scaron;tvene mreže GROOVE radija korisnik objavljuje neke osobne podatke, <span class="Apple-converted-space">&nbsp;&nbsp;</span></span>u dijelu objave istih preporuka je koristiti izravnu poruku,<span class="Apple-converted-space">&nbsp;&nbsp;</span>radije nego javnu objavu (public messaging).<span class="Apple-converted-space">&nbsp;&nbsp;</span>Svaka objava osobnih podataka koje korisnik javno dijeli na dru&scaron;tvenoj platformi je njegova osobna odgovornost.</p>
                        <p class="p15"><br /></p>
                        <p class="p14"><span class="s6">6.4<span class="Apple-converted-space">&nbsp;&nbsp;</span></span>Administrator stranice dru&scaron;tvene mreže GROOVE radija povremeno može izbrisati ( tamo gdje je to moguće ) one osobne podatke ili poruke korisnika za koje se može smatrati da su ih objavili gre&scaron;kom, slučajno ili nesvjesni posljedice takve javne objave te ih uputiti u tom slučaju na slanje izravnih a ne javnih poruka.</p>
                        <p class="p2"><br /></p>
                        <p class="p13"><strong>7.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Svrha kori&scaron;tenja i prikupljanja podataka</strong></p>
                        <p class="p3">7.1 Prikupljene osobne podatke koristit ćemo isključivo u svrhu za koju smo dobili privolu ili imamo legitimni interes za obradu, osim kada su od tih interesa jači interesi ili temeljna prava i slobode ispitanika koji zahtijevaju za&scaron;titu osobnih podataka.<span class="Apple-converted-space">&nbsp;</span></p>
                        <ul class="ul1">
                            <li class="li6">Za pobolj&scaron;anje kvalitete na&scaron;e usluge i programa</li>
                            <li class="li6">Za sudjelovanje u nagradnim igrama , promocijama i kampanjama</li>
                            <li class="li6">Za sudjelovanje u programskim sadržajima ( komentari , ankete )</li>
                            <li class="li6">Za pobolj&scaron;anje sadržaja na na&scaron;im web stranicama i dru&scaron;tvenim mrežama</li>
                            <li class="li6">Za dopunjavanje i ispravljanje Va&scaron;ih osobnih podataka i održavanje i unapređenje kontakta s Vama</li>
                            <li class="li6">Za mogućnost kontakta s vama temeljem Va&scaron;ih prijedloga, pohvala , pritužbi i drugih upita</li>
                        </ul>
                        <p class="p16"><br /></p>
                        <p class="p3">7.2 Prikupljeni podaci<span class="Apple-converted-space">&nbsp;&nbsp;</span>neće se prosljeđivati trećim stranama (niti izravno niti preko drugih zastupnika) osim ako to nije drugačije regulirano Ugovorom s trećom stranom i uz Va&scaron; izričiti pristanak.</p>
                        <p class="p3">7.3 Ako zbog potrebe programa i programskih sadržaja imamo namjeru dodatno obrađivati osobne podatke u svrhu koja je različita od one za koju su osobni podaci prikupljeni, prije te dodatne obrade upoznat ćemo Vas o svrsi obrade te<span class="Apple-converted-space">&nbsp;&nbsp;</span>Vam pružiti sve<span class="Apple-converted-space">&nbsp;&nbsp;</span>relevantne informacije i tražiti Va&scaron;u privolu.</p>
                        <p class="p3">7.4 Prikupljeni podaci služite će i za kontakt s vama kako bi ostvarili svoja prava kroz nagradne igre i kako bi Vas upoznali s novim usluga ili programskim sadržajima. To može biti putem telefona dru&scaron;tvenih mreža ili elektroničkom po&scaron;tom ako ste naveli da to želite. Ističemo da u svakom trenutku možete povući svoju privolu koju ste dali na način opisan pri kraju ove Izjave.</p>
                        <p class="p3">7.5<span class="Apple-converted-space">&nbsp;&nbsp;</span>Ako se ukaže potreba za jednostavnijom realizacijom postupaka preuzimanja nagrade osvojenih u nagradnim igrama<span class="Apple-converted-space">&nbsp;&nbsp;</span>postoji mogućnost da vas,<span class="Apple-converted-space">&nbsp;&nbsp;</span>uz va&scaron; pristanak,<span class="Apple-converted-space">&nbsp;&nbsp;</span>kontaktiraju treće strane (na&scaron;i klijenti i poslovni partneri s kojima je realizirana nagradna igra).</p>
                        <p class="p3">7.6 Ako Va&scaron; poziv u program sadrži uvredljiv ili neprimjeren sadržaj, Va&scaron;i podaci biti će sačuvani i snimljeni te postoji mogućnost blokiranja poziva kako bi se spriječili mogući novi incidenti, sukladno sigurnosti obrade i odredbama Opće uredbe.</p>
                        <p class="p3">6.7 Postoji mogućnost otkrivanja Va&scaron;ih osobnih podataka u skladu sa zakonskim odredbama, odnosno ukoliko postoji zahtjev za istim od strane određenih državnih tijela koji je u skladu sa zakonom (policija, sudstvo).</p>
                        <p class="p2"><br /></p>
                        <p class="p2"><br /></p>
                        <ul class="ul1">
                            <ol class="ol1">
                                <li class="li3"><strong>Maloljetne osobe i za&scaron;tita osobnih podataka</strong></li>
                            </ol>
                        </ul>
                        <p class="p3">Jasno nagla&scaron;avamo da ne prikupljamo i samim time i ne<span class="Apple-converted-space">&nbsp;&nbsp;</span>obrađujemo bilo kakve osobne podatke maloljetnih osoba bez privole i odobrenja roditelja odnosno zakonskog zastupnika. Maloljetne osobe mogu slobodno posjetiti na&scaron;u web stranicu odnosno na&scaron;e dru&scaron;tvene mreže i koristiti mobilnu aplikaciju ali pružanje bilo kakvih osobnih podataka<span class="Apple-converted-space">&nbsp;&nbsp;</span>mora biti uz privolu roditelja ili zakonskog zastupnika.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Eventualni podaci koji su prikupljeni bez na&scaron;e svjesne namjere, temeljem zahtjeva roditelja ili pravnog zastupnika bit će stavljeni na uvid i izbrisani.</p>
                        <p class="p2"><br /></p>
                        <ul class="ul1">
                            <ol class="ol1">
                                <li class="li3"><strong>Pravo uvida i ispravke</strong></li>
                            </ol>
                        </ul>
                        <p class="p3">9.1 U svakom trenutku imate pravo podnijeti zahtjev za uvid u osobne podatke koji su kod nas prikupljeni i pohranjeni. Imate pravo ispravke, dopune ili brisanja va&scaron;ih osobnih podataka kada su nepotpuni, netočni ili neažurni.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Ukoliko to želite, dovoljno je uputiti pismeni zahtjev službi za za&scaron;titu osobnih podataka i sigurnost na dolje navedenu adresu.</p>
                        <p class="p3">9.2 U svakom trenutku imate pravo odustati od dane privole za obradu Va&scaron;ih osobnih podataka. Dovoljno je poslati zahtjev na dolje<span class="Apple-converted-space">&nbsp;&nbsp;</span>navedenu adresu.</p>
                        <p class="p3">9.3 Prije pristupa podacima za svaki ćemo zahtjev utvrditi identitet podnositelja zahtjeva i opravdanost samog zahtjeva. U slučaju dvojbe možemo zatražiti dodatne informacije radi provjere Va&scaron;eg identiteta. Ukoliko smo zakonski obavezni odbiti Va&scaron; zahtjev ili postoji opravdani razlog da ne udovoljimo Va&scaron;em zahtjevu, o tome ćemo Vas obavijestiti u zakonskom roku<span class="Apple-converted-space">&nbsp;&nbsp;</span>te navesti razloge odbijanja.<span class="Apple-converted-space">&nbsp;</span></p>
                        <p class="p3">9.4 Svi va&scaron;i zahtjevi ukoliko su poslani temeljem navedenih uputa<span class="Apple-converted-space">&nbsp;&nbsp;</span>biti će zaprimljeni i obrađeni sukladno na&scaron;em Pravilima i Pravilima Uredbe.<span class="Apple-converted-space">&nbsp;&nbsp;</span>To služi za&scaron;titi<span class="Apple-converted-space">&nbsp;&nbsp;</span>Va&scaron;ih prava.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Ako biste se nekim od navedenih prava koristili prečesto i s očitom namjerom zlouporabe, možemo naplatiti administrativnu pristojbu ili odbiti obraditi Va&scaron; zahtjev.</p>
                        <p class="p3">9.5 Možda će postojati slučajevi i situacije kada nećemo moći udovoljiti Va&scaron;em zahtjevu da pristupite uvidu u Va&scaron;e osobne podatke jer bi to potencijalno ugrozilo privatnost drugih osoba<span class="Apple-converted-space">&nbsp;&nbsp;</span>ili bi ugrozilo mogući Ugovor o povjerljivosti. I u tom slučaju u potpunosti ćemo objasniti i obrazložiti razloge odbijanja.</p>
                        <p class="p17"><br /></p>
                        <ul class="ul1">
                            <ol class="ol1">
                                <li class="li3"><strong>Sigurnost i način obrade</strong></li>
                            </ol>
                        </ul>
                        <p class="p3">10.1 Utvrdili smo načela sigurnosti informacijskog sustava te smo postavili osnovne odrednice koje primjenjujemo u svim aspektima poslovanja te njihovom primjenom osiguravamo razinu sigurnosti informacijskog sustava i obrade osobnih podataka. Va&scaron;i osobni podaci obrađivani su na način kojim se osigurava odgovarajuća sigurnost, uključujući i za&scaron;titu od neovla&scaron;tene ili nezakonite obrade te od slučajnog gubitka, uni&scaron;tenja ili o&scaron;tećenja<span class="Apple-converted-space">&nbsp;&nbsp;</span>primjenom spomenutih organizacijskih i tehničkih mjera, protokola i procedura.</p>
                        <p class="p3">10.2 Va&scaron;i osobni podaci biti će zakonito, po&scaron;teno i transparentno obrađivani i neće biti obrađivani na način koji nije u skladu sa svrhom navedenom u ovoj izjavi.</p>
                        <p class="p3">10.3 Informacijsku sigurnost postižemo kontinuiranom primjenom, nadzorom i unaprjeđivanjem odgovarajućih kontrola, procesa i aktivnosti, uključujući edukaciju djelatnika,<span class="Apple-converted-space">&nbsp;&nbsp;</span>uvođenje i primjenu pravila, procesa, organizacijske strukture, te odgovarajuće programske i sklopovske podr&scaron;ke.</p>
                        <p class="p3">10.4 Upozoravamo da svaka komunikacija s vama putem elektroničke po&scaron;te ili putem web sučelja ili mobilne aplikacije unatoč poduzetim mjerama sigurnosti potencijalno može biti kompromitirana i u tom dijelu ne možemo garantirati potpunu sigurnost.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Sukladno tome, bilo koja informacija koja je poslana s va&scaron;e strane putem elektroničke po&scaron;te ili drugih digitalnih komunikacijskih platformi ( npr. WhatsApp, Viber i sl.) ili dru&scaron;tvenih mreža i sadrži osobne podatke, poslana je na va&scaron;u osobnu odgovornost.</p>
                        <p class="p2"><br /></p>
                        <p class="p13"><strong>11<span class="Apple-converted-space">&nbsp;&nbsp;</span>.</strong> <strong>Razdoblje čuvanja</strong></p>
                        <p class="p3">Podaci se čuvaju<span class="Apple-converted-space">&nbsp;&nbsp;</span>onoliko dugo koliko je potrebno kako bi ispunili obveze i usluge za koje ste nam dali svoju privolu a najdulje godinu dana, nakon čega će biti obrisani, osim ako zakonom nije drugačije određeno ili ako je to definirano Ugovornim odnosom.</p>
                        <p class="p3">Podatke ne možemo obrisati:</p>
                        <p class="p3">a) &nbsp; &nbsp;Ukoliko su potrebni za izvr&scaron;avanje ugovornih obaveza ili drugih zakonskih zahtjeva (npr. Zakon o računovodstvu)</p>
                        <p class="p3">b) &nbsp; &nbsp;Ukoliko se podaci aktivno upotrebljavaju u procesima temeljem zakonite svrhe za obradu podataka.</p>
                        <p class="p2"><br /></p>
                        <ol class="ol1">
                            <li class="li3"><strong>Kako nas kontaktirati</strong></li>
                        </ol>
                        <p class="p3">Ukoliko imate bilo kakvo pitanje koje je vezano uz prikupljanje i obradu va&scaron;ih osobnih podataka ili želite steći uvid u Va&scaron;e osobne<span class="Apple-converted-space">&nbsp;&nbsp;</span>podatke koje posjedujemo s ciljem provjere, izmjene ili brisanja slobodno nam se obratite pismenim putem na slijedeću adresu:</p>
                        <p class="p4"><strong>Enter Zagreb d.o.o.</strong></p>
                        <p class="p4"><strong>Av. V. Holjevca 29, 10 000 Zagreb</strong></p>
                        <p class="p4"><strong>Tel: 00385(1) 6690 600 ( 601 )</strong></p>
                        <p class="p18"><br /></p>
                        <p class="p3">Možete se obratiti i <strong>Voditelju službe za za&scaron;titu osobnih podataka</strong> : <a href="mailto:sluzbenik@enterzagreb.hr"><span class="s1">sluzbenik@enterzagreb.hr</span></a><span class="s1">&nbsp;</span>ili pozivom na : 01 66 90 600 ( 601 )</p>
                        <p class="p3">Va&scaron; zahtjev tretirat ćemo povjerljivo i povratno vas kontaktirati u najkraćem mogućem roku nakon &scaron;to ga analiziramo a najkasnije u roku 30 dana od primitka zahtjeva.</p>
                        <p class="p13"><strong>13.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Promjene u na&scaron;em Pravilniku o<span class="Apple-converted-space">&nbsp;&nbsp;</span>privatnosti</strong></p>
                        <p class="p3">13.1 S vremena na vrijeme postoji mogućnost da će se pojedini dijelovi ovog Pravilnika mijenjati ili nadopunjavati sukladno promijenjenoj poslovnoj praksi, zakonskoj regulativi, novim tehnolo&scaron;kim i informacijskim rje&scaron;enjima<span class="Apple-converted-space">&nbsp;&nbsp;</span>ili s namjerom jo&scaron; boljeg ispunjavanja na&scaron;ih obveza u dijelu prikupljanja i obrade Va&scaron;ih osobnih podataka. Ove izmjene biti će napravljene bez posebnih najava stoga Vam savjetujemo da s vremena na vrijeme provjerite i ponovno pročitate ovu izjavu o privatnosti .</p>
                        <p class="p3">13.2 Ova izjava o privatnosti je objavljena<span class="Apple-converted-space">&nbsp;&nbsp;</span>20.08.2021.</p>
                    </div>
                </div>
                <Fooer />
            </div>
        );
    }
}

export default Privacy;