import React, { Component } from 'react';



import firebase from 'firebase';

import fire from '../config/fire';


class Current extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            kljuc: [],
            lajk: '',
            is: '',
            av: [],
            voted: false,
            stisnuto: false,
            loading: true,
            user: null,
            uid: "",
            alert: false,
            alert2: false,
            malert2: false,
            malert3: false,
            prijava: false,
            ime: ""

        };
        this.guest = this.guest.bind(this);
    }

    getSong() {
        let formRef = fire.database().ref('sadaSviraWeb')
        formRef.on('value', child => {
            this.setState({
                ime: child.val(),


            });
        })
    }
    componentDidMount() {
        this.getSong()
        this.timer = setInterval(() => {
            this.getSong()
        }, 7000)
        let formRef = fire.database().ref('/pjesme').orderByChild("votes");
        formRef.on('value', child => {
            this.setState({
                pjesme: child.val(),
                loading: false

            });
        })


        const user = this.state.uid
        fire.database().ref(`users/${user}`)
            .on("value", snapshot => {
                snapshot.forEach(child => {
                    this.setState({ kljuc: child.val() });


                })
            });
        this.authListener()

    }
    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })
    }

    guest() {
        firebase.auth().signInAnonymously()
            .then(this.setState({ user: true, prijava: true }), setTimeout(() => {
                this.setState({ prijava: false });
            }, 1000))
            .catch(error => {
                this.setState({ user: true }, () => {

                })
            });
    }

    vote(id, bro, e) {
        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro
        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: true, alert: true }))

            .then(() => {
                setTimeout(() => {
                    this.setState({ alert: false });
                }, 1000)

                fire.database().ref(`users/${user}/${id}`).set(id)

            }).catch(() => {

            });
    }

    voteTwo(id, bro, e) {
        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro
        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: false, alert2: true }))

            .then(() => {
                setTimeout(() => {
                    this.setState({ alert2: false });
                }, 1000)
                fire.database().ref(`users/${user}/${id}`).set(id)

            }).catch(() => {

            });

    }
    get(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {

                this.setState({ voted: true, malert2: true })
                setTimeout(() => {
                    this.setState({ malert2: false });
                }, 1000)

            }).catch(() => {
            });
    }
    getTwo(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: false, malert3: true })
                setTimeout(() => {
                    this.setState({ malert3: false });
                }, 1000)

            }).catch(() => {

            });

    }
    logout() {
        fire.auth().signOut();

    }

    render() {

        const brojpjesama = this.state.pjesme === null ? '0' : Object.keys(this.state.pjesme).length
        const pjesme = this.state.pjesme
        const data = this.state.pjesme === null ? null : Object.values(pjesme).sort((a, b) => (a.broj + 1 < b.broj + 1) ? 1 : -1)
        return (

            <div style={ {} }>

                <div >
                    { this.state.loading === false ? <div >

                        { pjesme === null ? <div></div> : <div>
                            { Object.values(data).map((i, index) => (
                                <>
                                    { i.title == this.state.ime ? <div key={ index }>




                                        <div className="player-back" style={ { backgroundImage: "url(" + i.slika + ")", } } >

                                        </div>



                                    </div> : null }

                                </>
                            )) }
                        </div> }


                    </div> : null }
                </div>










            </div>
        );
    }
}

export default Current