import React, { Component } from "react";
import fire from "../config/fire";
import firebase from "firebase";
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';

// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class Lista_dodanih_pjesama extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            kljuc: [],
            lajk: '',
            is: '',
            av: [],
            voted: false,
            stisnuto: false,
            loading: true,
            user: null,
            uid: "",
            filter: "",

        };
    }
    guest() {
        firebase.auth().signInAnonymously()
            .then(this.setState({ user: true }), this.showAlert('success', 'Uspješno ste prijavljeni!'))
            .catch(error => {
                this.setState({ user: true }, () => {

                })
            });
    }
    componentDidMount() {

        let formRef = fire.database().ref('/pjesme')
        formRef.on('value', child => {
            this.setState({
                pjesme: child.val(),
                loading: false

            });
        })


        const user = this.state.uid
        fire.database().ref(`users/${user}`)
            .on("value", snapshot => {
                snapshot.forEach(child => {
                    this.setState({ kljuc: child.val() });


                })
            });
        this.authListener()

    }
    handleChange = event => {
        this.setState({ filter: event.target.value });
    };
    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })
    }


    vote(id, bro, e) {
        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro

        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: true }))

            .then(() => {

                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('yellow', 'Potvrdi svoj lajk!');
            }).catch(() => {

            });
    }

    voteTwo(id, bro, e) {

        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro
        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: false }))

            .then(() => {
                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('success', 'Vaš glas je dodan!');
            }).catch(() => {
                this.showAlert('danger', 'Vaš glas je ne može biti dodan!');
            });
        this.showAlert('yellow', 'Glasati možete jednom!');
    }
    get(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: true })
                this.showAlert('yellow', 'Potvrdi za uklanjanje lajka');
            }).catch(() => {
            });
    }
    getTwo(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: false })
                this.showAlert('danger', 'Vaš glas je uklonjen!');
            }).catch(() => {
                this.showAlert('danger', 'Greška');
            });
        this.showAlert('danger', 'Vaš glas je uklonjen!');
    }

    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }


    render() {
        const { filter } = this.state;
        const lowercasedFilter = filter.toLowerCase();
        const { t } = this.props;
        const pjesme = this.state.pjesme
        const data = this.state.pjesme === null ? null : Object.values(pjesme).sort((a, b) => (a.broj + 1 < b.broj + 1) ? 1 : -1)
        const filteredData = Object.values(data).filter(item => {
            return Object.keys(item).some(key =>
                typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
            );
        });
        return (
            <div  >
                <div className="container text-left">
                    { this.state.user === true ? null : <p style={ { color: '#999' } } >{ t('vt') } <a href="/login">{ t('vt2') }</a> { t('vt3') } <button style={ { padding: '0px' } } onClick={ () => this.guest() } className="btn text-bluee">{ t('vt4') }</button></p> }
                </div>
                <div className="container pb-2 pt-0 text-center">
                    <input placeholder="Search songs..." className="input" value={ filter } onChange={ this.handleChange } />
                </div>

                { this.state.loading === false ?
                    <div className="flexbox" >
                        { pjesme === null ? <div className="container"><p>No songs here!</p></div> : <>
                            { Object.values(filteredData).map((i, index) => (
                                <Fade bottom delay={ `${index}00` }>
                                    <div className="pjesma-grid" style={ {
                                        backgroundImage: "url(" + i.slika + ")", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100px', position: 'relative'
                                    } } >


                                        <div id="over-lay" style={ { width: '100%', height: '100%', display: 'flex' } }>
                                            <p className="p-3 title-grid">{ index + 1 }. { i.title } </p>
                                            <p className="p-3 title-grid">  <i id="like-grid" class="fa fa-heart"></i> { i.broj }</p>
                                            <small className="m-auto">{ i.artist }</small>
                                            <div style={ { position: 'absolute', zIndex: 3, bottom: '0px', right: ' 0px', padding: '10px', background: 'rgba(10, 20, 10, 0.8) ', borderRadius: '10px 0px 0px 0px' } }>
                                                { this.state.user === true ? <div>  { this.state.stisnuto === false ?
                                                    <i id="like-grid" onClick={ this.vote.bind(this, i.id_pjesme, i.votes ? Object.keys(i.votes).length : 0) } class="far fa-heart"></i>
                                                    :
                                                    <i id="like-grid" onClick={ this.voteTwo.bind(this, i.id_pjesme, i.votes ? Object.keys(i.votes).length : 0) } class="far fa-heart" ></i>
                                                }

                                                    { this.state.voted === false ?

                                                        <i id="dislike" onClick={ this.get.bind(this, i.id_pjesme, i.votes ? Object.keys(i.votes).length : 0) } class="fas fa-heart-broken"></i>
                                                        : <i id="dislike" onClick={ this.getTwo.bind(this, i.id_pjesme, i.votes ? Object.keys(i.votes).length : 0) } class="fas fa-heart-broken"></i>
                                                    }</div> : null }
                                            </div>
                                        </div>











                                    </div>

                                </Fade>

                            )) }
                        </> }

                        { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } style={ { position: 'fixed', top: 100, zIndex: 100, left: 10 } } role='alert'>
                            <div className='container'>
                                { this.state.alertData.message }
                            </div>
                        </div> }

                    </div> :
                    <div style={ { marginTop: '30px', minHeight: '500px', margin: '0 auto', textAlign: 'center', width: '100%' } }><img src={ 'https://cutewallpaper.org/21/music-equalizer-gif/android-audio-visualizer.gif' } /></div>
                }


            </div >
        );
    }
}
Lista_dodanih_pjesama.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Lista_dodanih_pjesama);
