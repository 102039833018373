import React, { Component } from "react";
import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';
import MusicLinks from "../Components/MusicLinks";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";
import ReactHtmlParser from 'react-html-parser';
// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class Contact extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Header />
                <div className="padding-top" >
                    <div className="container">

                        <div className="jumbotron bg-transparent">

                            <div >


                                <div >
                                    <h4 style={ { fontSize: '38px' } } className="groovin"> { ReactHtmlParser(t('kontakt1')) }</h4> <br />

                                    <p style={ { fontSize: '24px' } } className="groovin">  { t('kontakt2') }</p>
                                    <br />

                                </div>
                                <div >
                                    <h5 className="text-whitw">
                                        <a href="https://www.facebook.com/groovemebaby" target="_blank"><i class="bi bi-facebook "></i> GROOVE</a> </h5>
                                    <h5 className="text-white">
                                        <a href="https://www.instagram.com/groovemebaby/" target="_blank"> <i class="bi bi-instagram"></i> @groovemebaby</a></h5>
                                    <h5 className="text-white">
                                        <a href="https://www.youtube.com/channel/UCvm6fCdSJKykK85I3R6j04A" target="_blank"><i class="bi bi-youtube"></i> Youtube</a>
                                    </h5>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <Fooer />
            </div>
        );
    }
}
Contact.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Contact);