import React, { Component } from "react";
import CookieNotice from '@mirzalikic/react-cookie-notice';
const domainGroupId = 'c5653025-0b20-4523-9fe4-35805f1679ed';

class Cookie extends Component {
    constructor (props) {
        super(props);
        this.state = {
            show: true,
        }
    }



    toggle() {
        this.setState({ show: false })
    }
    componentDidMount() {

    }


    render() {

        return (
            <CookieNotice
                onSave={ (cookies) => {
                    console.log(cookies);
                } }
                onInit={ (cookies) => {
                    console.log(cookies);
                } }
                acceptAllButtonText="Accept"
                cookiePrefix="my-cookie-"
                preferencesButtonText="Postavke"

                cookies={ [
                    { name: 'necessary', checked: true, editable: true, default: true, title: 'Nužni', text: '' },
                    { name: 'marketing', checked: false, editable: true, title: 'Marketing', text: ' ' },
                    { name: 'static', checked: false, editable: true, title: 'Statični', text: '', name: 'static', checked: false, editable: true, title: 'Za postavke', text: '' }
                ] }>
                <h3>This website uses cookies</h3>
                <p>We use cookies that help the website to function and also to track how you interact with it. We will only use the cookies if you consent to it by clicking on "Accept all cookies". You can also manage individual cookie preferences.</p>
            </CookieNotice>

        );
    }
}

export default Cookie;