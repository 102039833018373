import React, { Component } from "react";
import { Link } from "react-router-dom";
import Top from "../Components/Top";
import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';
import MusicLinks from "../Components/MusicLinks";
import Header from "../Components/Navigacija";
import Fooer from "../Components/Footer";
// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class Music extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Header />
                <div className="padding-top">

                    <Top />
                </div>
                <Fooer />
            </div>
        );
    }
}
Music.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Music);