import React, { Component } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import "./Header.css";
import fire from "../config/fire";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';
// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();

class Header extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: null,
            uid: "",
            admin: ""

        };
    }
    handleSetLanguage = (key) => () => {
        setLanguage(key);

        this.set()


    };
    handleSetLanguageHR = (key) => () => {
        setLanguage(key);

        this.hr()



    };
    set() {
        this.setState({
            lang: 'en'
        }, function () {
            /**
             * Safe to access state
             */
            localStorage.setItem('lang', JSON.stringify(this.state.lang));

        });
    }
    hr() {
        this.setState({
            lang: 'hr'
        }, function () {
            /**
             * Safe to access state
             */
            localStorage.setItem('lang', JSON.stringify(this.state.lang));

        });
    }
    componentDidMount() {

        $(document).ready(function () {
            'use strict';

            $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
            $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
            $('.menu > ul').before('<div id="nav-icon1"><span></span><span></span><span></span></div>');
            $('.menu > ul > li').hover(function (e) {
                if ($(window).width() > 943) {
                    $(this)
                        .children('ul')
                        .stop(true, false)
                        .slideToggle(400);
                    e.preventDefault();
                }
            });
            $('.menu > ul >li').click(function () {
                if ($(window).width() <= 943) {
                    $(this)
                        .children('ul')
                        .fadeToggle(350);
                }
            });


            $('#nav-icon1').click(function (e) {
                $('.menu > ul').fadeToggle(300);
                e.preventDefault();
            });
        });
        $(window).resize(function () {
            $('.menu > ul > li')
                .children('ul')
                .hide();
            $('.menu > ul').removeClass('show-on-mobile');
        });

        $(document).ready(function () {
            $('#nav-icon1').click(function () {
                $(this).toggleClass('open');
            });
        });
        $(document).ready(function () {

            'use strict';

            var c, currentScrollTop = 0,
                navbar = $('.menu-container');

            $(window).scroll(function () {
                var a = $(window).scrollTop();
                var b = navbar.height();

                currentScrollTop = a;

                if (c < currentScrollTop && a > b + b) {
                    navbar.addClass("scrollUp");
                } else if (c > currentScrollTop && !(a <= b)) {
                    navbar.removeClass("scrollUp");
                }
                c = currentScrollTop;
            });
            $("row").addClass('menu.container').removeAttr('html')
        });

        this.authListener()

    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
                fire.database().ref(`korisnici/${id}`)
                    .on("value", snapshot => {
                        snapshot.forEach(child => {
                            this.setState({ admin: child.val() });


                        })
                    });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })




    }
    logout() {
        fire.auth().signOut();

    }

    render() {
        console.log(this.state.admin)
        const { t } = this.props;
        return (
            <div class="menu-container ">
                <div class="menu">
                    <a href="/" class="logo text-white"><img src="https://admin.dzungla.hr/slikeTwo/GROOVE_app_icon.png" /></a>

                    <ul class="clearfix">
                        <li ><a href="/" id="as" className="d-flex" >Home</a></li>


                        <li ><a href="/music" className="d-flex">Music</a></li>


                        <li><a href="/contact" className="d-flex">Contact</a></li>

                        { this.state.user === true ?
                            <li><a onClick={ this.logout }>Sign out</a></li> : null
                        }
                        <li id="ppp" > <a onClick={ () => window.open("http://groove.com.hr/player", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes') } target="_blank"><div class="note">

                            <div class="pointer text-center">
                                <span className="text-center"> <img style={ { width: '30px', height: '30px' } } src="http://admin.dzungla.hr/slikeTwo/plays.png" /></span>
                            </div>
                        </div></a></li>

                        <div >



                        </div>
                    </ul>
                    <a className="start-play text-center" onClick={ () => window.open("http://groove.com.hr/player", '_blank', 'location=yes,height=570,width=2070,scrollbars=yes,status=yes') } target="_blank"><div class="note">

                        <div class="pointer">
                            <span> <i class="far fa-play-circle"></i></span>
                        </div>
                    </div></a>
                    <div className="d-flex language">
                        <b onClick={ this.handleSetLanguage('en') }>
                            EN /
                        </b>
                        <b className="ml-1" onClick={ this.handleSetLanguageHR('hr') }>
                            HR
                        </b>
                    </div>
                </div>

            </div >
        );
    }
}

Header.propTypes = {
    t: PropTypes.func.isRequired,
};
export default Header;
