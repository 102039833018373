import * as firebase from 'firebase';

var config = {
  apiKey: "AIzaSyA1_68zcw5JYl_Cy7XpDremXYkch9_73xc",
  authDomain: "sliceline-25184.firebaseapp.com",
  databaseURL: "https://sliceline-25184.firebaseio.com",
  projectId: "sliceline-25184",
  storageBucket: "sliceline-25184.appspot.com",
  messagingSenderId: "576832996780",
  appId: "1:576832996780:web:bafc04fec5b0a80f"
};
const fire = firebase.initializeApp(config);
export default fire;