import React, { Component } from 'react'
import fire from '../config/fire'
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase";
import 'bootstrap/dist/css/bootstrap.css';
import ContentLoader, { Facebook } from 'react-content-loader'
import Avatar from '@material-ui/core/Avatar';
import { Button, Row, Col, Card, } from '@material-ui/core';
import { withRouter } from 'react-router';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Header from "./Navigacija";
class Detalji extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            title: [],
            slika: [],
            broj: [],
            artist: [],

            id: [],
            loading: true,
            language: false,




        };
        this.izbrisi = this.izbrisi.bind(this);

    }

    resetForm() {
        this.refs.CheckoutFormForm.reset();

    }


    componentDidMount() {

        const ordersRef = fire.database().ref(`pjesme`)

        ordersRef.once('value', snapshot => {
            snapshot.forEach(child => {
                const movie = child.val()
                if (movie.id_pjesme == this.props.match.params.id) {
                    this.setState({

                        pjesme: this.state.pjesme.concat([child.val().pjesme]),
                        title: this.state.title.concat([child.val().title]),
                        slika: this.state.slika.concat([child.val().slika]),
                        broj: this.state.broj.concat([child.val().broj]),
                        artist: this.state.artist.concat([child.val().artist]),
                        id: child.key,
                        loading: false

                    });
                    console.log(this.state.id)
                }
            })

        })
    }
    reload() {
        setTimeout(function () { window.reload(1); }, 2000);
    }

    izbrisi() {
        const id = this.state.id

        return fire.database().ref(`pjesme`).child(id).remove().then(() => {

            this.showAlert('success', 'Pjesma je izbrisana!');
            window.location.reload();

        }).catch(() => {
            this.showAlert('danger', 'Pjesma ne može biti izbrisana');
        });;
    }
    sendMessage(e) {
        e.preventDefault();

        const id = this.state.id
        const params = {

            title: this.inputTitle.value,
            artist: this.inputArtist.value,
            slika: this.inputSlika.value,

        };

        if (id) {
            fire.database().ref('pjesme').child(id).update(params).then(() => {

                this.showAlert('success', 'Vaša pjesma je ažurirana!');
                setTimeout(() => {
                    this.props.history.go()
                }, 1000)

            }).catch(() => {
                this.showAlert('danger', 'Pjesma ne može biti ažurirana');
            });



            this.resetForm()
            this.showAlert('success', 'Vaša pjesma je ažurirana!');

        } else {
            this.showAlert('warning', 'Molimo Vas unesite sva polja');
        };
    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }



    render() {

        const { match, location, history } = this.props
        return (
            <div>
                <Header />
                <div className="padding-top" style={ { background: '#000', alignItems: 'center', margin: '0 auto', textAlign: 'left' } }>
                    <div className="container- p-3">
                        <div className="p-3">
                            <button className="m-1 btn bg-white" variant="text" onClick={ () => history.push('/dodaj-pjesmu') } >Dodaj pjesmu</button>
                            <button className="m-1 btn bg-white" variant="text" onClick={ () => history.push('/pjesme') } >Pjesme</button>
                            <Button className="m-1" variant="contained" color="secondary" onClick={ this.izbrisi }>Delete</Button>
                        </div>
                        { this.state.loading ?
                            <div style={ { minHeight: '100vh' } }><Facebook /></div> :
                            <div className="row"
                            >
                                <div className="col-sm-7" style={ { background: 'white', borderRadius: 11, marginLeft: '5px' } }>
                                    <form style={ { marginTop: '10px', background: '', padding: '1em' } } id="form" onSubmit={ this.sendMessage.bind(this) } ref='CheckoutFormForm' >
                                        <button className="btn btn-success"
                                            type='submit'>Ažuriraj pjesmu</button>
                                        <div className="form-group">
                                        </div>
                                        <div className='form-group'>
                                            <label className="text-dark">Ime pjesme</label>
                                            <input type='text' className='form-control' id='title' defaultValue={ this.state.title } placeholder={ this.state.title } ref={ title => this.inputTitle = title } />
                                        </div>
                                        <div className='form-group'>
                                            <label className="text-dark">Izvođač</label>
                                            <input type='text' className='form-control' id='weight' defaultValue={ this.state.artist } placeholder={ this.state.artist } ref={ artist => this.inputArtist = artist } />
                                        </div>

                                        <div className='form-group '>
                                            <label className="text-dark">Slika</label>
                                            <input type='text' className='form-control' id='poster' defaultValue={ this.state.slika } placeholder={ this.state.slika } ref={ slika => this.inputSlika = slika } />

                                        </div>






                                    </form>
                                </div>
                                <div className="col-sm" style={ { borderRadius: 11, marginLeft: '7px' } }>



                                    { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } role='alert'>
                                        <div className='container'>
                                            { this.state.alertData.message }
                                        </div>
                                    </div> }

                                    <div className="">
                                        <div className=" " style={ { display: "flex", flexWrap: "wrap", margin: '0px auto', width: '100%', justifyContent: 'left', } }>
                                            { this.state.pjesme.map((data, index) =>

                                                <div className="card  b-0" style={ { width: "100%", border: 0, background: 'transparent' } } >
                                                    <div style={ { textAlign: 'center', background: 'white' } }>
                                                        <img style={ { width: '60%', height: 'auto' } } alt={ this.state.title[index] } src={ this.state.slika[index] } />
                                                    </div>
                                                    <ul style={ { listStyleType: "none", background: '#555', padding: '20px', } } id="lista-detalji" className='text-left'>
                                                        <p className="text-left text-bluee">{ this.state.title[index] }</p>
                                                        <li className="text-white">Autor: <b>{ this.state.artist[index] }</b></li>

                                                        <li className="text-white"> Id pjesme:   { this.state.id }</li>
                                                        <li className="text-white"> Broj lajkova:   { this.state.broj[index] }</li>

                                                    </ul>


                                                </div>

                                            ) }
                                        </div>


                                    </div>
                                </div>
                            </div> }

                    </div>
                </div>
            </div>
        )
    }


}
export default Detalji;