import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";
class Events extends Component {
    render() {
        return (
            <div className="">
                <Header />
                <div className="container-fluid">
                    <div className="row ">
                        <Fade bottom delay={ `100` }>    <div className="col-sm events-col-sm one">
                            <div className="events-text1">
                                <div className="events-text">
                                    <Fade bottom delay={ `200` }>  <h1 style={ { fontWeight: 'bold', fontSize: '50px' } }>Leave organizaiton of<br /> Your holidays to us</h1></Fade>
                                </div>
                            </div>
                        </div></Fade>
                        <Fade bottom delay={ `200` }>     <div className="col-sm events-col-sm ">
                            <div className="events-text">
                                <div className="row ">
                                    <div className="col-sm events-text p-4 "><h4 className="text-dark"><i class="bi bi-arrow-right"></i> New event</h4><br /><p style={ { color: "#999" } }>Groove events</p></div>
                                    <div className="col-sm"><img style={ { width: '100%', height: 'auto', borderRadius: '5px' } } src="https://images.unsplash.com/photo-1505236858219-8359eb29e329?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1430&q=80" /></div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm mt-3"><img style={ { width: '100%', height: 'auto', borderRadius: '5px' } } src="https://images.unsplash.com/photo-1518890569493-668df9a00266?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1267&q=80" /></div>
                                    <div className="col-sm events-text  p-4 mb-4"><h4 className="text-dark"><i class="bi bi-arrow-right"></i> Read more</h4><br /><p style={ { color: "#999" } }>Groove events</p></div>
                                </div>
                                <button style={ { position: 'absolute', right: 0, bottom: 0, borderRadius: 0, padding: '25px', width: '250px' } } className="btn button-black">CONTACT US <i class="bi bi-arrow-right"></i></button>
                            </div>

                        </div></Fade>

                    </div>
                </div>
                <Fooer />
            </div >
        );
    }
}

export default Events;