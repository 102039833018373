import { Link } from 'react-router-dom';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MusicLinks from './MusicLinks';
import fire from '../config/fire';
import Product_list from './Now_playing';

const Hist = () => {
    const [track, setTrack] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        let repeat;

        async function fetchData() {
            try {
                const res = await fetch("https://corsgroove.herokuapp.com/http://c5.hostingcentar.com:8307/played.html?sid=1");
                const json = await res.text();

                const jso = json
                setTrack(jso);
                fire.database().ref('povijest').set(jso)
                setLoading(false)

                repeat = setTimeout(fetchData, 4000); // request again after a minute
            } catch (error) {
                console.error(error.message)
            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }
    }, []);

    return (
        <div style={ { position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999, height: '100vh' } }>

            <div style={ { visibility: 'hidden' } }><Product_list /></div>

            <div style={ { position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999, } }>     <div className="container his">  <h1 style={ { padding: '20px', paddingTop: '42px' } } className="groovin-mobile">Just played</h1> </div>
                { ReactHtmlParser(track) }</div>

        </div>
    );
};

export default Hist