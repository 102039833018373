import React, { Component } from "react";
import fire from "../config/fire";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Row, Col, Card, } from '@material-ui/core';
import $ from 'jquery'
import { Link } from "react-router-dom";
import firebase from "firebase";
import MusicLinks from "../Components/MusicLinks";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";
class SvePjesme extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            kljuc: [],
            lajk: '',
            is: '',
            av: [],
            voted: false,
            stisnuto: false,
            loading: true,
            user: null,
            uid: ""

        };


    }
    guest() {
        firebase.auth().signInAnonymously()
            .then(this.setState({ user: true }), this.showAlert('success', 'Uspješno ste prijavljeni!'))
            .catch(error => {
                this.setState({ user: true }, () => {

                })
            });
    }
    componentDidMount() {

        let formRef = fire.database().ref('/pjesme')
        formRef.on('value', child => {
            this.setState({
                pjesme: child.val(),
                loading: false

            });
        })


        const user = this.state.uid
        fire.database().ref(`users/${user}`)
            .on("value", snapshot => {
                snapshot.forEach(child => {
                    this.setState({ kljuc: child.val() });


                })
            });
        this.authListener()

    }
    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })
    }


    vote(id, bro, e) {
        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro

        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: true }))

            .then(() => {

                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('yellow', 'Potvrdi svoj lajk!');
            }).catch(() => {

            });
    }

    voteTwo(id, bro, e) {

        const lajk = true
        const user = this.state.uid
        const votes = { lajk, user }
        const broj = bro
        fire.database().ref(`pjesme/${id}/votes`).child(user).set(votes).then(() => {
            fire.database().ref(`pjesme/${id}/broj`).set(broj)
        }).then(this.setState({ stisnuto: false }))

            .then(() => {
                fire.database().ref(`users/${user}/${id}`).set(id)
                this.showAlert('success', 'Vaš glas je dodan!');
            }).catch(() => {
                this.showAlert('danger', 'Vaš glas je ne može biti dodan!');
            });
        this.showAlert('yellow', 'Glasati možete jednom!');
    }
    get(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: true })
                this.showAlert('yellow', 'Potvrdi za uklanjanje lajka');
            }).catch(() => {
            });
    }
    getTwo(id, bro, e) {
        const user = this.state.uid
        const broj = bro
        fire.database().ref(`pjesme/${id}/broj`).set(broj)
        fire.database().ref(`pjesme/${id}/votes`).child(user).remove()
        fire.database().ref(`users/${user}/${id}`).remove()
            .then(() => {
                this.setState({ voted: false })
                this.showAlert('danger', 'Vaš glas je uklonjen!');
            }).catch(() => {
                this.showAlert('danger', 'Greška');
            });
        this.showAlert('danger', 'Vaš glas je uklonjen!');
    }

    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }


    render() {
        const pjesme = this.state.pjesme
        const data = this.state.pjesme === null ? null : Object.values(pjesme).sort((a, b) => (a.broj + 1 < b.broj + 1) ? 1 : -1)
        return (
            <div>
                <Header />
                <div className="padding-top" >

                    <div className="container text-left">
                        <h1 style={ { color: '#999' } }>All songs</h1>


                    </div>
                    { this.state.loading === false ?
                        <>
                            { pjesme === null ? null : <List className="p-3" >
                                { Object.values(data).map((i, index) => (
                                    <div>
                                        <ListItem className="pjesma2" id={ index + 1 === 1 ? 'one' : 'two' } alignItems="flex-start">

                                            <div style={ { backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '110px', height: '100px', borderRadius: '15px 0px 0px 15px' } } className="">
                                                <img style={ { width: '110px', height: '100px', objectFit: 'cover' } } src={ i.slika } />


                                            </div>

                                            <ListItemAvatar>
                                                <div className="p-1 pt-4 ">
                                                    <small className="m-1" >{ index + 1 }.</small>
                                                    <small className="m-1">
                                                        <i class="fa fa-heart"></i> { i.broj }</small>
                                                </div>
                                            </ListItemAvatar>
                                            <ListItemText style={ { padding: '15px' } }
                                                primary={ i.title }
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"

                                                            color="textPrimary"
                                                        >
                                                            { i.artist }
                                                        </Typography>

                                                    </React.Fragment>
                                                }

                                            />


                                            <ListItemSecondaryAction>

                                                <a href={ `detalji/${i.id_pjesme}` }><i class="far fa-eye"></i></a>


                                            </ListItemSecondaryAction>
                                            <Divider variant="inset" component="li" />
                                        </ListItem>

                                    </div>

                                )) }
                                { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } style={ { position: 'absolute', top: 0, zIndex: 10, left: 10 } } role='alert'>
                                    <div className='container'>
                                        { this.state.alertData.message }
                                    </div>
                                </div> }

                            </List> }
                        </> :
                        <div style={ { marginTop: '30px', minHeight: '500px', margin: '0 auto', textAlign: 'center', width: '100%' } }><img src={ 'https://cutewallpaper.org/21/music-equalizer-gif/android-audio-visualizer.gif' } /></div>
                    }
                </div>
                <Fooer />
            </div >
        );
    }
}

export default SvePjesme;