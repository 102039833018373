import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import fire from './config/fire';
import firebase from 'firebase'
import 'bootstrap/dist/css/bootstrap.css';
import Header from './Components/Navigacija';
import Fooer from './Components/Footer';
import PropTypes from 'prop-types';
import en from './en.json';
import hr from './hr.json';
import ReactHtmlParser from 'react-html-parser';
// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';

// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            user: null,
            uid: ''
        }

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.signup = this.signup.bind(this);
        this.guest = this.guest.bind(this);
    }

    componentDidMount() {

        this.authListener()
    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })
    }
    guest() {
        firebase.auth().signInAnonymously()
            .then(this.setState({ user: true }), this.showAlert('success', 'Uspješno ste prijavljeni!'))
            .catch(error => {
                this.setState({ user: true }, () => {

                })
            });
    }

    login(e) {

        e.preventDefault();
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u) => {
            this.showAlert('success', 'Uspješno ste prijavljeni!');


        }).catch(() => { this.showAlert('danger', 'Podaci za prijavu nisu točni!'); });
    }

    signup(e) {
        e.preventDefault();
        fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((u) => {
        }).then((u) => { console.log(u) }).catch((error) => { console.log(error); })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    showAlert(type, message) {
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <Header />
                <div className="container padding-top">
                    <div style={ { height: '15vh' } }>
                        { this.state.alert && <div className={ `alert alert-${this.state.alertData.type}` } role='alert'>
                            <div className='container'>
                                { this.state.alertData.message }
                            </div>
                        </div> }
                        <h1>{ t('login') }</h1>
                    </div>

                    <div style={ { margin: '0 auto', maxWidth: '600px' } } className=" align-items-center bg-transparent justify-content-center ">


                        <form>
                            <input className='form-control' type="email" placeholder="Email" name="email" value={ this.state.email } onChange={ this.handleChange } />
                            <br />
                            <input className='form-control' type="password" placeholder="Password" name="password" value={ this.state.password } onChange={ this.handleChange } />
                            <br />
                            <div className="d-flex">
                                <button className="btn button-blue text-white mr-2" type="submit" onClick={ this.login }>{ t('login') }</button>
                            </div>
                        </form>
                        <button className="btn button-blue mt-2 text-white" onClick={ this.guest } >{ t('logingGuest') }</button>
                        <p className="pt-4">{ t('account') } <Link to="registracija">{ t('account2') }</Link></p>

                    </div></div >
                <Fooer />
            </div>
        );
    }
}
Login.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Login);