import React, { Component } from "react";
import Fooer from "../Components/Footer";
import Lista_dodanih_pjesama from "../Components/Lista_dodanih_pjesama";
import Header from "../Components/Navigacija";
import Top from "../Components/Top";

class Lista_pjesama extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="padding-top " style={ { background: 'black' } }>
                    <Top />

                </div>
                <Fooer />
            </div>

        );
    }
}

export default Lista_pjesama;