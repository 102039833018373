import React, { Component } from "react";
import Dodaj_pjesme from "../Components/Dodaj_pjesme";
import Fooer from "../Components/Footer";
import Header from "../Components/Navigacija";
import fire from "../config/fire";
class Dodaj_pjesmu extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: null,
            uid: "",
            admin: ""

        };
    }
    componentDidMount() {
        this.authListener()
    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
                fire.database().ref(`korisnici/${id}`)
                    .on("value", snapshot => {
                        snapshot.forEach(child => {
                            this.setState({ admin: child.val() });


                        })
                    });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })




    }
    logout() {
        fire.auth().signOut();

    }

    render() {
        return (
            <div>
                <Header />
                <div className="padding-top">
                    { this.state.admin === 'admin' && this.state.user === true ? <Dodaj_pjesme /> : null }

                </div>
                <Fooer />
            </div>
        );
    }
}

export default Dodaj_pjesmu;