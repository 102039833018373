import { Button } from '@material-ui/core';
import React, { Component } from 'react'
import Now_playing from '../Components/Now_playing';
import fire from '../config/fire'
import History from '../Components/History';
import Player from '../Components/Player';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Dodaj_pjesme from '../Components/Dodaj_pjesme';
import BrojLajkova from '../Components/BrojLajkova';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import en from '../en.json';
import hr from '../hr.json';
import ReactHtmlParser from 'react-html-parser';
// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getDefaultLanguage
} from 'react-switch-lang';
import Fooer from '../Components/Footer';
import Header from '../Components/Navigacija';
// Do this two lines only when setting up the application
setTranslations({ en, hr });
const a = JSON.parse(localStorage.getItem('lang'));
setDefaultLanguage(a === null ? 'hr' : a);
// If you want to remember selected language
setLanguageCookie();
class Home extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoading: true
        };


    }
    handleSetLanguage = (key) => () => {
        setLanguage(key);

        this.set()


    };
    handleSetLanguageHR = (key) => () => {
        setLanguage(key);

        this.hr()



    };
    set() {
        this.setState({
            lang: 'en'
        }, function () {
            /**
             * Safe to access state
             */
            localStorage.setItem('lang', JSON.stringify(this.state.lang));

        });
    }
    hr() {
        this.setState({
            lang: 'hr'
        }, function () {
            /**
             * Safe to access state
             */
            localStorage.setItem('lang', JSON.stringify(this.state.lang));

        });
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 3000);
    }
    render() {

        const { t } = this.props;
        return (
            <div>
                <Header />
                <div className="" style={ { background: 'black' } }>
                    { this.state.isLoading === true ? <div style={ { display: 'flex', minHeight: '100vh' } }>           <Fade bottom delay={ `200` }> <img style={ { margin: 'auto', width: '50%', height: '100%' } } src="https://admin.dzungla.hr/slike/FeeltheGROOVE.png" /></Fade></div> : <div>
                        <div id="height" ></div>
                        <div className="headerr">

                            <video className="video" playsInline muted autoPlay loop >
                                <source src="https://admin.dzungla.hr/slike/Comp1.mp4" type="video/mp4" />
                            </video>

                            <div className="container-fluid play  bb">

                                <div className="overlay">


                                    <div style={ { height: 'auto' } } className="d-flex h-90 text-left align-items-center ">
                                        <div className="row" style={ { margin: '0 auto', display: 'block' } }>

                                            <div className="col-sm-6 text-white" id="height-img">                          <Fade bottom delay={ `100` }><img style={ { width: '60%', height: 'auto', } } src="https://admin.dzungla.hr/slike/Logotip.png" /> </Fade>
                                                <br /> <Fade bottom delay={ `200` }><p>{ ReactHtmlParser(t('home.title')) } </p></Fade><br /> <Fade bottom delay={ `300` }> <p>{ ReactHtmlParser(t('home.titleTwo')) }</p></Fade>
                                            </div>
                                            <div className="col-sm-4 pt-4 text-white text-left " style={ { paddingTop: '3em', padding: '2em' } }><Fade bottom delay={ `400` }> <ul className="li-groov" style={ { fontSize: '18px' } }>
                                                { ReactHtmlParser(t('music.one')) }  </ul></Fade><br />
                                                <Fade bottom delay={ `500` }>  <p> { ReactHtmlParser(t('music.two')) }</p></Fade>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="headerr2" style={ { backgorund: 'black' } }>

                            <video className="video" playsInline muted autoPlay loop >
                                <source src="https://admin.dzungla.hr/slike/Comp3.mp4" type="video/mp4" />
                            </video>

                            <div className="container-fluid play  bb">

                                <div className="overlay">


                                    <div style={ { height: 'auto' } } className="d-flex h-100 text-center align-items-center pb-3">
                                        <div className="row w-20 " style={ { margin: '0 auto' } }>

                                            <div className="col-sm text-white text-left">
                                                <Fade bottom delay={ `100` }>  <h1 className="groovin">{ t('home.feell') }</h1></Fade>
                                                <br />  <Fade bottom delay={ `200` }> <p style={ { fontSize: '18px', marginTop: '10px' } }> { t('home.feel') }</p></Fade>
                                                <br />
                                                <Fade bottom delay={ `500` }>   <div className="row">
                                                    <div className="col-sm">
                                                        <a href="https://play.google.com/store/apps/details?id=com.reactnativeradio" target="_blank" >
                                                            <img style={ { border: '1px solid white', borderRadius: '10px', width: '60%' } } src="https://www.pngkey.com/png/full/15-157952_download-on-the-app-store-get-it-on.png" />
                                                        </a>
                                                    </div>
                                                    <div className="col-sm">
                                                        <a href="https://apps.apple.com/hr/app/groove-hr/id1582639530?l=hr" target="_blank">
                                                            <img style={ { border: '1px solid white', borderRadius: '10px', width: '63%' } } src="https://img1.pnghut.com/19/5/6/7Mse1uKPXC/monochrome-brand-handheld-devices-silhouette-logo.jpg" />
                                                        </a>
                                                    </div>
                                                </div></Fade>
                                            </div>
                                            <div className="col-sm p-3 text-white text-center " >
                                                <Fade bottom delay={ `600` }>   <img style={ { width: '100%', height: 'auto', } } src="https://admin.dzungla.hr/slikeTwo/groove-app.png" /></Fade>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="headerr3">

                            <video className="video" playsInline muted autoPlay loop >
                                <source src={ require('../Astets/Blobic_2.mp4') } type="video/mp4" />
                            </video>


                            <div className="container-fluid play h-100 w-100 bb">

                                <div className="overlay">


                                    <div style={ { height: '500px' } } className="d-flex h-90 text-center align-items-center ">
                                        <div className="container w-20 text-left" style={ { margin: '0 auto' } }>
                                            <div className=" text-white text-left">  <Fade bottom delay={ `500` }> <h1 className="groovin" style={ { fontWeight: 'lighter' } }></h1></Fade></div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> }

                </div>
                <Fooer />
            </div>
        )
    }
}
Home.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Home);