import React, { Component } from "react";
import fire from "../config/fire";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
class BrojLajkova extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pjesme: [],
            title: [],
            artist: [],
            votes: [],
            slika: [],
            id_pjesme: []
        };
    }
    componentDidMount() {

        const ordersRef = fire.database().ref(`pjesme`).orderByChild("votes")

        ordersRef.once('value', snapshot => {
            snapshot.forEach(child => {
                const movie = child.val()

                this.setState({

                    pjesme: this.state.pjesme.concat([child.val().pjesme]),
                    title: this.state.title.concat([child.val().title]),
                    artist: this.state.artist.concat([child.val().artist]),
                    votes: this.state.votes.concat([child.val().votes]),
                    slika: this.state.slika.concat([child.val().slika]),
                    id_pjesme: this.state.id_pjesme.concat([child.val().id_pjesme]),

                });


            })

        })
    }

    vote(id, e) {
        console.log(id);
        const lajk = true
        const user = "korisnik"
        const votes = { lajk, user }

        fire.database().ref(`pjesme/${id}`).child("votes").push(votes)

    }

    render() {
        const pjesme = this.state.pjesme

        return (
            <div>


                <List >
                    { Object.values(pjesme).map((i, index) => (
                        <ListItem className="pjesma" alignItems="flex-start">

                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={ this.state.slika[index] } />
                            </ListItemAvatar>
                            <ListItemText
                                primary={ this.state.title[index] }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"

                                            color="textPrimary"
                                        >
                                            { this.state.title[index] }
                                        </Typography>

                                    </React.Fragment>
                                }

                            />
                            <ListItemSecondaryAction>
                                <i class="far fa-heart"></i>
                                <i class="fas fa-heart-broken"></i>
                                <button onClick={ this.vote.bind(this, this.state.id_pjesme[index]) } >Vote</button>
                            </ListItemSecondaryAction>
                            <Divider variant="inset" component="li" />
                        </ListItem>

                    )) }



                </List>

            </div>
        );
    }
}

export default BrojLajkova;