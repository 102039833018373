import React from 'react';
import fire from '../config/fire';
import "./Player.css";
const Product_list = () => {
    const [track, setTrack] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        let repeat;

        async function fetchData() {
            try {
                const res = await fetch("https://corsgroove.herokuapp.com/http://c5.hostingcentar.com:8307/currentsong?sid=1");
                const json = await res.text();


                setTrack(json);
                setLoading(false)
                fire.database().ref('sadaSviraWeb').set(json)
                repeat = setTimeout(fetchData, 5000); // request again after a minute
            } catch (error) {
                console.error(error.message)
            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }
    }, []);

    return (

        <div className="padding-top-no-height">

            { loading ? <p>Buffering...</p> :
                <div>

                    <small style={ { fontSize: '15px', color: '#fafafa', } } >{ track }</small></div>
            }

        </div>
    );
};

export default Product_list