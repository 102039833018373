import React, { Component } from "react";
import "./Footer.css";
import fire from "../config/fire";
import Fade from 'react-reveal/Fade';
class Fooer extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: null,
            uid: "",
            admin: ""

        };
    }
    componentDidMount() {
        this.authListener()
    }

    authListener() {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid
                this.setState({ user: true, uid: id });
                fire.database().ref(`korisnici/${id}`)
                    .on("value", snapshot => {
                        snapshot.forEach(child => {
                            this.setState({ admin: child.val() });


                        })
                    });
            }
            else {
                this.setState({ user: null, e: null });
            }
        })




    }
    logout() {
        fire.auth().signOut();

    }

    render() {
        return (
            <div>
                <footer class="main-footer">
                    <div class="container">
                        <div class="footer-content">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                                    <div class="logo-widget footer-widget">
                                        <figure class="logo-box"><a href="#">       <Fade bottom delay={ `200` }><img style={ { width: '50%', height: 'auto' } } src="https://admin.dzungla.hr/slike/Logotip.png" alt="groove" /></Fade></a></figure>
                                        <div class="textt p-1 mb-2">
                                            <Fade bottom delay={ `200` }>     <small></small></Fade>
                                        </div>
                                        <ul class="footer-social">
                                            <li><a href="https://www.facebook.com/groovemebaby" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.instagram.com/groovemebaby/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.youtube.com/channel/UCvm6fCdSJKykK85I3R6j04A" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 offset-lg-2 footer-column pt-3">
                                    <div class="service-widget footer-widget">
                                        <div class="footer-title">Services
                                            <hr style={ { background: 'white', height: '0.5px' } } />
                                        </div>
                                        <ul class="list">
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/music">Music</a></li>
                                            <li><a href="/contact">Contact</a></li>

                                            <li>        { this.state.admin === 'admin' && this.state.user === true ? <li><a href="/dodaj-pjesmu">Dodaj pjesme</a></li> : null }</li>
                                            <li>        { this.state.admin === 'admin' && this.state.user === true ? <li><a href="/pjesme">Pjesme</a></li> : null }</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm pt-3">
                                    <div class="service-widget footer-widget">
                                        <div class="footer-title">Contact
                                            <hr style={ { background: 'white', height: '0.5px' } } />
                                        </div>
                                        <ul class="list">
                                            <li><a href="mailto:groove@groove.com.hr">groove@groove.com.hr</a></li>
                                            <li>Avenija Većeslava Holjevca 29</li>
                                            <li>10 000 Zagreb, Croatia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div class="footer-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 column">
                                <div class="copyright"><a href="#">Groove</a> &copy; 2021 All Right Reserved</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 column">
                                <ul class="footer-nav">
                                    <li><a href="/cookie">Cookie</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/impressum">Impressum</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Fooer;